@import '../../../scss/media';

.btn {
   display: inline-flex;
   justify-content: center;
   align-items: center;
   column-gap: 10px;
   border-radius: 50px;
   background: #006AFE;
   padding: 7px 16px;
   padding: clamp(0.375rem, 0.063rem + 0.313vw, 0.438rem) clamp(0.813rem, -0.125rem + 0.938vw, 1rem);
   // 7px > 6px
   // 16px > 12px

   color: #FFF;
   text-align: center;
   font-family: "IBM Plex Mono";
   font-size: 18px;
   font-size: clamp(0.875rem, -0.375rem + 1.25vw, 1.125rem);
   // 18px > 14px
   font-weight: 400;
   line-height: 24px;
   line-height: clamp(1.25rem, 0rem + 1.25vw, 1.5rem);
   // 24px > 18px
   letter-spacing: 0.36px;
   text-transform: uppercase;
   cursor: pointer;

   transition: .4s;

   @include desktop {
      padding: 6px 13px;

      font-size: 14px;
      line-height: normal;
      letter-spacing: 0.24px;
   }

   @include tablet {
      font-size: 12px;
      padding: 5px 12px;
   }

   .icon {
      width: 20px;
      width: clamp(0.938rem, -0.625rem + 1.563vw, 1.25rem);
      // 20px > 15px
      height: 20px;
      height: clamp(0.938rem, -0.625rem + 1.563vw, 1.25rem);
      // 20px > 15px

      @include desktop {
         width: 15px;
         height: 15px;
      }

      @include tablet {
         width: 14px;
         height: 14px;
      }

      svg {
         width: 100%;
         height: 100%;
      }
   }

   &.gray {
      background: #C9C9C9;
      color: #121212;
   }

   &.light_gray {
      color: #121212;
      background: #F5F5F7;
   }

   &.white {
      color: #121212;
      background: #fff;
   }

   &.orange {
      color: #fff;
      background: #F26419;
   }

   @include hover {
      opacity: .7;
   }
}