@use '../../../scss/media';

.city_block {
   display: flex;
   flex-direction: column;
   position: relative;
   z-index: 3;
   flex-shrink: 0;

   @include media.mobile {
      position: absolute;
      top: 13px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 121;
   }

   &-render {
      display: flex;
      align-items: center;
      column-gap: 10px;
      max-width: 250px;
      padding: 6px 16px 8px 16px;
      padding: 6px media.fl(16, 14) 6px media.fl(16, 14);
      border-radius: 50px;
      background: #F5F5F7;
      cursor: pointer;
      transition: .4s;

      @include media.hover {
         background: #E8E8EB;
      }

      @include media.desktop {
         max-width: 210px;
         padding: 6px 14px 6px 14px;
      }

      @include media.tablet {
         padding: 5px 12px;
         max-width: 170px;
      }

      @include media.mobile {
         padding: 6px 14px 6px 14px;
         max-width: 205px;
      }

      span {
         color: #121212;
         text-align: center;
         font-family: "IBM Plex Mono";
         font-size: 18px;
         font-size: media.fl(18, 14);
         font-weight: 400;
         line-height: 26px;
         line-height: media.fl(26, 22);
         letter-spacing: 0.36px;
         text-transform: uppercase;

         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;

         @include media.desktop {
            font-size: 14px;
            line-height: normal;
            letter-spacing: 0.28px;
         }

         @include media.tablet {
            font-size: 12px;
            letter-spacing: 0.24px;
         }

         @include media.mobile {
            font-size: 14px;
            letter-spacing: 0.28px;
         }
      }

      .icon {
         display: flex;
         justify-content: center;
         align-items: center;
         flex-shrink: 0;
         transition: .4s;

         &.--point {
            width: 14px;
            height: 16px;
            transform: translateY(-1px);

            @include media.tablet {
               display: none;
            }

            @include media.mobile {
               display: flex;
            }
         }

         &.--arrow {
            width: 10px;
            width: media.fl(10, 8);
            height: 6px;
            height: media.fl(6, 4);

            @include media.desktop {
               width: 8px;
               height: 4px;
            }
         }

         svg {
            width: 100%;
            height: 100%;
         }
      }
   }

   &_wrap {
      padding: 30px 15px 30px 30px;
      padding: media.fl(30, 15) 15px media.fl(30, 15) media.fl(30, 15);
      border-radius: 10px;
      background: #F5F5F7;
      width: 260px;
      width: media.fl(260, 232);
      max-width: 270px;
      max-width: media.fl(270, 240);
      position: absolute;
      top: calc(100% + 27px);
      top: calc(100% + media.fl(27, 16));
      right: 0;
      opacity: 0;
      visibility: hidden;

      transition: .4s;

      @include media.desktop {
         top: calc(100% + 16px);
         padding: 16px;
         width: 232px;
         max-width: 240px;
      }

      @include media.mobile {
         width: 250px;
         max-width: 260px;
         left: 50%;
         right: auto;
         transform: translateX(-50%);
      }
   }

   &_list {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      row-gap: 10px;
      overflow-y: auto;
      max-height: 340px;
      max-height: media.fl(340, 288);
      height: 100%;
      padding-right: 15px;

      @include media.desktop {
         max-height: 288px;
         row-gap: 12px;
      }

      &::-webkit-scrollbar {
         width: 4px;

         @include media.desktop {
            width: 2px;
         }
      }

      &::-webkit-scrollbar-track {
         background-color: #E8E8EB;
      }

      &::-webkit-scrollbar-thumb {
         background-color: #C9C9C9;
      }

      &_it {
         display: flex;
         justify-content: flex-start;
         align-items: flex-start;

         &-btn {
            color: #121212;
            font-family: "IBM Plex Mono";
            font-size: 18px;
            font-size: media.fl(18, 14);
            font-weight: 400;
            line-height: 26px;
            line-height: media.fl(26, 22);
            letter-spacing: 0.36px;
            text-transform: uppercase;

            cursor: pointer;
            transition: .4s;

            @include media.desktop {
               font-size: 14px;
               line-height: normal;
               letter-spacing: 0.28px;
            }

            @include media.tablet {
               font-size: 12px;
               letter-spacing: 0.24px;
            }

            @include media.mobile {
               font-size: 14px;
               letter-spacing: 0.28px;
            }

            @include media.hover {
               opacity: .7;
            }
         }
      }
   }

   &.active {
      .city_block-render {
         background: #E8E8EB;

         .icon {
            &.--arrow {
               transform: rotate(180deg);
            }
         }


      }

      .city_block_wrap {
         opacity: 1;
         visibility: visible;
      }
   }
}