@import '../../scss/media';

.footer {
   margin-top: 160px;
   margin-top: clamp(7.5rem, -5rem + 12.5vw, 10rem);
   // 160px > 120px

   @include desktop {
      margin-top: 120px;
   }

   @include tablet {
      margin-top: 30px;
   }

   &_wrap {
      padding-top: 30px;
      padding-bottom: 55px;
      // 50px > 30px
      border-top: 1px solid #C9C9C9;

      @include desktop {
         padding-top: 30px;
         padding-bottom: 55px;
      }

      @include tablet {
         padding-bottom: 30px;
      }

      .row {
         row-gap: 138px;
         row-gap: clamp(4.375rem, -16.875rem + 21.25vw, 8.625rem);
         // 138px > 70px

         @include desktop {
            row-gap: 70px;
         }

         @include mobile {
            row-gap: 32px;
         }
      }

      &_nav {
         ul {
            display: flex;
            column-gap: 50px;
            column-gap: clamp(1.5rem, -6.625rem + 8.125vw, 3.125rem);
            // 50px > 24px

            @include desktop {
               column-gap: 24px;
            }

            @include tablet {
               // justify-content: space-between;
            }

            li {
               display: flex;

               a {
                  color: #121212;
                  text-align: center;
                  font-size: 16px;
                  font-size: clamp(0.875rem, 0.25rem + 0.625vw, 1rem);
                  // 16px > 14px
                  font-weight: 400;
                  line-height: 24px;
                  line-height: clamp(1.375rem, 0.75rem + 0.625vw, 1.5rem);
                  // 24px > 22px
                  letter-spacing: 0.32px;
                  transition: .4s;

                  @include desktop {
                     font-size: 14px;
                     line-height: 22px;
                     letter-spacing: 0.28px;
                  }

                  @include tablet {
                     font-size: 12px;
                     line-height: normal;
                     letter-spacing: 0.24px;
                  }

                  @include hover {
                     color: #006AFE;
                  }
               }
            }
         }
      }

      &_contacts {
         display: flex;
         justify-content: flex-end;
         align-items: flex-start;
         column-gap: 7px;

         @include desktop {
            column-gap: 15px;
         }

         @include tablet {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;
            row-gap: 47px;
            padding-left: 25px;
         }

         @include mobile {
            padding-left: 0px;
            row-gap: 32px;
            justify-content: flex-start;
            align-items: flex-start;
         }

         &_info {
            display: flex;
            column-gap: 80px;
            column-gap: clamp(0.938rem, -19.375rem + 20.313vw, 5rem);
            // 80px > 15px
            flex: 1 1 auto;
            justify-content: flex-end;
            align-items: flex-start;

            @include desktop {
               column-gap: 15px;
            }

            @include tablet {
               column-gap: 35px;
            }

            @include mobile {
               column-gap: 12px;
               justify-content: flex-start;
               align-items: flex-start;
            }

            &-item {
               display: flex;
               flex-direction: column;
               justify-content: flex-start;
               align-items: flex-start;
               width: 100%;
               max-width: 320px;
               max-width: clamp(18.75rem, 12.5rem + 6.25vw, 20rem);
               // 320px > 300px

               color: #121212;
               font-size: 16px;
               font-size: clamp(0.875rem, 0.25rem + 0.625vw, 1rem);
               // 16px > 14px
               font-weight: 400;
               line-height: 24px;
               line-height: clamp(1.375rem, 0.75rem + 0.625vw, 1.5rem);
               // 24px > 22px
               letter-spacing: 0.32px;

               @include desktop {
                  max-width: 300px;

                  font-size: 14px;
                  line-height: 22px;
                  letter-spacing: 0.28px;
               }

               @include tablet {
                  font-size: 12px;
                  line-height: normal;
                  letter-spacing: 0.24px;
                  width: auto;
                  max-width: 250px;
               }

               a {
                  color: inherit;
                  font-size: inherit;
                  font-weight: inherit;
                  line-height: inherit;
                  letter-spacing: inherit;
                  transition: .4s;

                  @include hover {
                     color: #006AFE;
                  }
               }
            }
         }

         &_social {
            display: flex;
            column-gap: 20px;
            column-gap: clamp(0.625rem, -2.5rem + 3.125vw, 1.25rem);
            // 20px > 10px

            @include desktop {
               column-gap: 10px;
            }

            @include mobile {
               width: 100%;
            }

            .btn {
               @include mobile {
                  width: 100%;
                  max-width: 50%;
               }
            }
         }
      }

      &_logo {
         display: flex;
         width: 100%;
         max-width: 172px;
         max-width: clamp(8.313rem, -3.875rem + 12.188vw, 10.75rem);
         // 172px > 33px
         height: 50px;
         height: clamp(2.375rem, -1.375rem + 3.75vw, 3.125rem);
         // 50px > 38px
         overflow: hidden;

         @include desktop {
            max-width: 133px;
            height: 38px;
         }

         img,
         svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
         }
      }

      &_privacy {
         display: flex;
         column-gap: 40px;
         justify-content: flex-end;
         align-items: flex-end;
         height: 100%;

         @include desktop {
            justify-content: space-between;
         }

         @include tablet {
            column-gap: 7px;
            align-items: flex-start;
         }

         @include mobile {
            flex-wrap: wrap;
            row-gap: 24px;
         }

         a {
            transition: .4s;

            @include hover {
               color: #006AFE;
            }
         }

         &-item {
            color: #C9C9C9;
            font-size: 16px;
            font-size: clamp(0.75rem, -0.5rem + 1.25vw, 1rem);
            // 16px > 12px
            font-weight: 400;
            line-height: 24px;
            line-height: clamp(1.25rem, 0rem + 1.25vw, 1.5rem);
            // 24px > 20px
            letter-spacing: 0.32px;

            @include desktop {
               font-size: 12px;
               font-size: clamp(0.688rem, 0.375rem + 0.417vw, 0.75rem);
               // 12px > 11px
               line-height: normal;
               letter-spacing: 0.24px;
            }

            @media screen and (max-width:1199px) {
               font-size: 12px;
               width: 100%;
               max-width: 20%;
            }

            @media screen and (max-width:850px) {
               font-size: 12px;
               width: 100%;
               max-width: 25%;
            }

            @include mobile {
               max-width: calc(50% - 3.5px);
            }

            a {
               color: inherit;
               font-size: inherit;
               font-weight: inherit;
               line-height: inherit;
               letter-spacing: inherit;
            }
         }
      }
   }
}