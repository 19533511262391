@import '../../scss/media';

.contacts_pg {
   margin-top: 60px;

   @include tablet {
      margin-top: 30px;
   }

   &_wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 50px;

      @include tablet {
         row-gap: 30px;
      }

      &_list {
         row-gap: 20px;

         @include tablet {
            row-gap: 7px;
         }

         @include mobile {
            row-gap: 10px;
         }

         .row {
            row-gap: 20px;
            height: 100%;

            @include tablet {
               height: auto;
               row-gap: 0px;
            }
         }

         &_item {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 49px;
            padding: clamp(2.438rem, -0.688rem + 3.125vw, 3.063rem);
            // 49px > 39px
            border-radius: 10px;
            border: 1px solid #E8E8EB;
            background: #FFF;
            row-gap: 30px;

            height: 100%;

            @include desktop {
               flex-direction: row;
               column-gap: 30px;
               padding: 39px;
               padding: clamp(1.813rem, -1.313rem + 4.167vw, 2.438rem);
               // 39px > 29px
            }

            @include tablet {
               padding: 19px;
               flex-direction: column;
               row-gap: 20px;
            }

            @include mobile {
               flex-direction: row;
               column-gap: 20px;
            }

            &.social_btn {
               .contacts_pg_wrap_list_item_bot {
                  row-gap: 30px;
                  flex: 1 1 auto;
                  justify-content: space-between;

                  @include tablet {
                     row-gap: 20px
                  }

                  @include mobile {
                     row-gap: 10px;
                     flex: unset;
                  }

                  .contacts_pg_wrap_list_item-link_btn {
                     @include mobile {
                        width: auto;
                     }
                  }
               }
            }

            &_bot {
               display: flex;
               justify-content: flex-start;
               align-items: flex-start;
               flex-direction: column;
               row-gap: 20px;
               row-gap: clamp(1rem, -0.25rem + 1.25vw, 1.25rem);
               // 20px > 16px

               @include desktop {
                  row-gap: 16px;
                  width: 100%;
                  max-width: calc(100% - 30px - 50px);
               }

               @include tablet {
                  row-gap: 10px;
                  width: 100%;
                  max-width: 100%;
               }

               @include mobile {
                  max-width: calc(100% - 40px - 20px);
                  row-gap: 4px;
               }

               &_btns {
                  display: flex;
                  flex-direction: column;
                  row-gap: 10px;

                  @include mobile {
                     flex-direction: row;
                     column-gap: 6px;
                  }
               }
            }

            &-icon {
               display: flex;
               justify-content: center;
               align-items: center;
               width: 60px;
               width: clamp(3.125rem, 0rem + 3.125vw, 3.75rem);
               // 60px > 50px
               height: 60px;
               height: clamp(3.125rem, 0rem + 3.125vw, 3.75rem);
               // 60px > 50px
               overflow: hidden;

               @include desktop {
                  width: 50px;
                  height: 50px;
               }

               @include tablet {
                  width: 40px;
                  height: 40px;
               }

               svg {
                  width: 100%;
                  height: 100%;
               }
            }

            &-title {
               color: #121212;
               font-size: 26px;
               font-size: clamp(1.125rem, -1.375rem + 2.5vw, 1.625rem);
               // 26px > 18px
               font-weight: 400;
               line-height: 34px;
               line-height: clamp(1.625rem, -0.875rem + 2.5vw, 2.125rem);
               // 34px > 26px
               letter-spacing: 0.52px;

               @include desktop {
                  font-size: 18px;
                  line-height: 26px;
                  letter-spacing: 0.36px;
               }

               @include mobile {
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: 0.32px;
               }
            }

            &-descr {
               color: #121212;
               font-size: 18px;
               font-size: clamp(1rem, 0.375rem + 0.625vw, 1.125rem);
               // 18px > 16px
               font-weight: 400;
               line-height: 26px;
               line-height: clamp(1.563rem, 1.25rem + 0.313vw, 1.625rem);
               // 26px > 25px
               letter-spacing: 0.36px;

               margin-top: 20px;
               margin-top: clamp(1rem, -0.25rem + 1.25vw, 1.25rem);
               // 20px > 16px

               @include desktop {
                  font-size: 16px;
                  line-height: 25px;
                  letter-spacing: 0.32px;

                  margin-top: 16px;
               }

               @include tablet {
                  font-size: 14px;
                  line-height: 20px;
                  letter-spacing: 0.28px;
                  margin-top: 10px;
               }

               @include mobile {
                  margin-top: 4px;
               }
            }

            &-link {
               display: flex;
               justify-content: flex-start;
               align-items: flex-start;

               color: #121212;
               font-size: 18px;
               font-size: clamp(1rem, 0.375rem + 0.625vw, 1.125rem);
               // 18px > 16px
               font-weight: 400;
               line-height: 26px;
               line-height: clamp(1.563rem, 1.25rem + 0.313vw, 1.625rem);
               // 26px > 25px
               letter-spacing: 0.36px;

               transition: .4s;

               @include desktop {
                  font-size: 16px;
                  line-height: 25px;
                  letter-spacing: 0.32px;
               }

               @include tablet {
                  font-size: 14px;
                  line-height: 20px;
                  letter-spacing: 0.28px;
               }

               @include hover {
                  color: #006AFE;
               }
            }

            &-link_line {
               display: flex;
               justify-content: flex-start;
               align-items: flex-start;

               color: #006AFE;
               font-family: "IBM Plex Mono";
               font-size: 16px;
               font-weight: 400;
               line-height: 24px;
               letter-spacing: 0.32px;
               text-transform: uppercase;
               text-decoration: underline;
               text-decoration-thickness: 1px;
               text-underline-offset: 5px;

               transition: .4s;

               @include tablet {
                  font-size: 14px;
                  line-height: 24px;
                  letter-spacing: 0.32px;
               }

               @include hover {
                  opacity: .7;
               }
            }

            &-link_btn {
               display: flex;
               justify-content: flex-start;
               align-items: center;
               width: 100%;
               padding: 7px 16px;
               padding: clamp(0.375rem, 0.063rem + 0.313vw, 0.438rem) clamp(0.813rem, -0.125rem + 0.938vw, 1rem);
               // 7px > 6px
               // 16px > 13px
               column-gap: 10px;
               border-radius: 50px;
               background: #006AFE;

               color: #FFF;
               text-align: center;
               font-family: "IBM Plex Mono";
               font-size: 18px;
               font-size: clamp(0.875rem, -0.375rem + 1.25vw, 1.125rem);
               // 18px > 14px
               font-weight: 400;
               line-height: 24px;
               line-height: clamp(1.25rem, 0rem + 1.25vw, 1.5rem);
               // 24px > 20px
               letter-spacing: 0.36px;
               text-transform: uppercase;

               transition: .4s;

               @include hover {
                  opacity: .7;
               }

               @include desktop {
                  padding: 6px 13px;
                  font-size: 14px;
                  line-height: normal;
                  letter-spacing: 0.24px;
               }

               @include tablet {
                  padding: 5px 12px;

                  font-size: 12px;
                  line-height: normal;
                  letter-spacing: 0.24px;
               }

               @include mobile {
                  padding: 5px 10px;
               }

               .icon {
                  width: 20px;
                  width: clamp(1rem, -0.25rem + 1.25vw, 1.25rem);
                  // 20px > 16px
                  height: 20px;
                  height: clamp(1rem, -0.25rem + 1.25vw, 1.25rem);
                  // 20px > 16px

                  @include desktop {
                     width: 16px;
                     height: 16px;
                  }

                  svg {
                     width: 100%;
                     height: 100%;
                  }
               }
            }

            &.map {
               display: flex;
               flex-direction: row;
               justify-content: space-between;
               align-items: flex-start;
               column-gap: 30px;
               padding: 29px;

               @include desktop {
                  padding: 39px;
               }

               @include tablet {
                  padding: 19px;
                  column-gap: 20px;
               }

               @include mobile {
                  flex-direction: column;
                  row-gap: 20px;
               }

               .contacts_pg_wrap_list_item_left {
                  display: flex;
                  column-gap: 50px;
                  column-gap: clamp(1.875rem, -4.375rem + 6.25vw, 3.125rem);
                  // 50px > 30px
                  width: 100%;
                  max-width: 730px;
                  max-width: clamp(22.5rem, -93.125rem + 115.625vw, 45.625rem);
                  // 730px > 360px

                  padding: 20px 0 20px 20px;
                  padding-top: clamp(0rem, -6.25rem + 6.25vw, 1.25rem);
                  // 20px > 0px
                  padding-bottom: clamp(0rem, -6.25rem + 6.25vw, 1.25rem);
                  // 20px > 0px
                  padding-left: clamp(0rem, -6.25rem + 6.25vw, 1.25rem);
                  // 20px > 0px

                  @include desktop {
                     column-gap: 30px;
                     max-width: 360px;
                  }

                  @include tablet {
                     padding: 0px;
                     column-gap: 20px;
                     max-width: 280px;
                     flex-direction: column;
                     row-gap: 20px;
                  }

                  @include mobile {
                     max-width: 100%;
                     flex-direction: row;
                  }


                  &_content {
                     display: flex;
                     flex-direction: column;
                     row-gap: 20px;
                     row-gap: clamp(1rem, -0.25rem + 1.25vw, 1.25rem);
                     // 20px > 16px
                     width: 100%;
                     max-width: calc(100% - 60px - 50px);
                     max-width: calc(100% - clamp(3.125rem, 0rem + 3.125vw, 3.75rem) - clamp(1.875rem, -4.375rem + 6.25vw, 3.125rem));
                     // 60px > 50px
                     // 50px > 30px

                     @include desktop {
                        max-width: calc(100% - 50px - 30px);
                        row-gap: 16px;
                     }

                     @include tablet {
                        row-gap: 16px;
                        max-width: 100%;
                     }

                     @include mobile {
                        row-gap: 4px;
                        max-width: calc(100% - 40px - 20px);
                     }
                  }
               }

               .contacts_pg_wrap_list_item-image {
                  display: block;
                  width: 100%;
                  max-width: 750px;
                  max-width: clamp(29.375rem, -58.125rem + 87.5vw, 46.875rem);
                  // 750px > 470px
                  height: 217px;
                  height: clamp(11.188rem, -0.688rem + 11.875vw, 13.563rem);
                  // 217px > 179px
                  overflow: hidden;
                  border-radius: 10px;

                  @include desktop {
                     max-width: 470px;
                     height: 179px;
                  }

                  @include tablet {
                     max-width: 328px;
                     height: auto;
                     aspect-ratio: 164/107;
                  }

                  @include mobile {
                     max-width: calc(100% - 40px - 20px);
                     margin-left: auto;
                     aspect-ratio: 249/136;
                  }

                  img {
                     width: 100%;
                     height: 100%;
                     object-fit: cover;
                  }
               }
            }
         }
      }
   }
}