@import '../../scss/media';

.header {
  padding: 20px 30px;
  padding: clamp(0.625rem, -2.5rem + 3.125vw, 1.25rem) clamp(0.813rem, -4.5rem + 5.313vw, 1.875rem);
  // 20px > 10px
  // 30px > 13px
  // position: fixed;
  // width: 100%;
  // top: 0px;
  // left: 0px;
  // z-index: 99;

  @include desktop {
    padding: 10px 13px;
  }

  &_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;

    @include tablet {
      column-gap: 12px;
    }

    &-logo {
      display: flex;
      width: 100%;
      max-width: 172px;
      max-width: clamp(7.313rem, -9.875rem + 17.188vw, 10.75rem);
      // 172px > 117px
      height: auto;
      max-height: 50px;
      overflow: hidden;

      svg path {
        fill: #006afe;
      }

      @include desktop {
        width: 117px;
        max-width: 117px;
      }

      img,
      svg {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }

    &_nav {

      @include mobile {
        padding: 20px 0;
        position: absolute;
        top: 100%;
        left: -100%;
        width: 100%;
        height: calc(100dvh - 54px);
        overflow-y: auto;
        background: #fff;
        z-index: 99;
        visibility: hidden;

        transition: .4s;
        display: none;
      }

      ul {
        display: flex;
        align-items: center;
        column-gap: 12px;

        @include tablet {
          column-gap: 4px;
        }

        li {
          display: flex;

          a,
          button {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            column-gap: 10px;
            border-radius: 50px;
            background: #F5F5F7;
            padding: 6px 16px 8px 16px;
            padding-top: clamp(0.313rem, 0rem + 0.313vw, 0.375rem);
            // 6px > 5px
            padding-right: clamp(0.875rem, 0.25rem + 0.625vw, 1rem);
            // 16px > 14px
            padding-bottom: clamp(0.313rem, -0.625rem + 0.938vw, 0.5rem);
            // 8px > 5px
            padding-left: clamp(0.875rem, 0.25rem + 0.625vw, 1rem);
            // 16px > 14px
            border: 1px solid transparent;

            color: #121212;
            text-align: center;
            font-family: "IBM Plex Mono";
            font-size: 18px;
            font-size: clamp(0.875rem, -0.375rem + 1.25vw, 1.125rem);
            // 18px > 14px
            font-weight: 400;
            line-height: 26px;
            line-height: clamp(1.375rem, 0.125rem + 1.25vw, 1.625rem);
            // 26px > 22px
            letter-spacing: 0.36px;
            text-transform: uppercase;
            cursor: pointer;

            transition: .4s;

            &.current-link {
              background: #E8E8EB;
            }

            @include hover {
              background: #E8E8EB;
            }

            @include desktop {
              padding: 5px 14px;

              font-size: 14px;
              line-height: normal;
              letter-spacing: 0.28px;
            }

            @include tablet {
              padding-left: 12px;
              padding-right: 12px;

              font-size: 12px;
              line-height: normal;
              letter-spacing: 0.24px;
            }
          }

          &.drops {
            position: relative;

            button {

              .icon {
                transition: .4s;
              }

              &.active {
                .icon {
                  transform: rotate(180deg);
                }
              }
            }

            .sub-menu {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;
              row-gap: 20px;
              padding: 30px;
              border-radius: 10px;
              background: #F5F5F7;
              position: absolute;
              top: calc(100% + 24px);
              left: 0;
              width: max-content;
              z-index: 4;
              opacity: 0;
              visibility: hidden;
              transition: .4s;

              &.active {
                opacity: 1;
                visibility: visible;
              }

              li {

                a {
                  justify-content: flex-start;
                  align-items: flex-start;
                  text-align: left;
                  background: unset;
                  border: unset;
                  padding: unset;
                  border-radius: unset;

                  @include hover {
                    color: #006afe;
                  }
                }
              }
            }
          }
        }
      }

      &.active {

        @include mobile {
          visibility: visible;
          left: 0%;
        }
      }
    }

    &_right {
      display: flex;
      align-items: flex-start;
      column-gap: 13px;

      @include mobile {
        display: flex;
        align-items: center;
        column-gap: 7px;
      }

      .header_wrap_right-btn {
        @include tablet {
          position: fixed;
          bottom: 20px;
          left: 20px;
          z-index: 3;
        }

        @include mobile {
          position: unset;
        }
      }
    }
  }
}