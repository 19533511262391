@import '../../../scss/media';

.benefits {
   row-gap: 20px;

   @include tablet {
      row-gap: 10px;
   }

   &_item {
      display: flex;
      column-gap: 50px;
      column-gap: clamp(1.875rem, -4.375rem + 6.25vw, 3.125rem);
      // 50px > 30px
      padding: 49px;
      padding: clamp(2.438rem, -0.688rem + 3.125vw, 3.063rem);
      // 49px > 39px
      border-radius: 10px;
      border: 1px solid #E8E8EB;
      background: #FFF;
      height: 100%;

      @include desktop {
         padding: 39px;
         flex-direction: column;
         row-gap: 20px;
         column-gap: 30px;
      }

      @include tablet {
         padding: 19px;
      }

      @include mobile {
         height: auto;
      }

      &-icon {
         display: flex;
         width: 100%;
         max-width: 60px;
         max-width: clamp(3.125rem, 0rem + 3.125vw, 3.75rem);
         // 60px > 50px
         height: 60px;
         height: clamp(3.125rem, 0rem + 3.125vw, 3.75rem);
         // 60px > 50px
         aspect-ratio: 1/1;
         overflow: hidden;

         @include desktop {
            max-width: 50px;
            height: 50px;
         }

         @include tablet {
            max-width: 40px;
            height: 40px;
         }

         svg {
            width: 100%;
            height: 100%;
         }
      }

      &_content {
         display: flex;
         flex-direction: column;
         justify-content: center;
         row-gap: 20px;
         width: 100%;
         max-width: calc(100% - 50px - 60px);
         max-width: calc(100% - clamp(1.875rem, -4.375rem + 6.25vw, 3.125rem) - clamp(3.125rem, 0rem + 3.125vw, 3.75rem));
         // 50px > 30px
         // 60px > 50px

         @include desktop {
            max-width: 100%;
         }

         &-title {}

         &-text {}
      }
   }
}