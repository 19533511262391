@import '../../scss/media';

.services_page {
   display: flex;
   padding-top: 60px;
   overflow: hidden;
   min-height: calc(100dvh - 90px - 160px);
   min-height: calc(100dvh - clamp(3.563rem, -6.75rem + 10.313vw, 5.625rem) - clamp(7.5rem, -5rem + 12.5vw, 10rem));

   @include desktop {
      min-height: calc(100dvh - 57px - 120px);
   }

   @include tablet {
      padding-top: 30px;
      min-height: calc(100dvh - 57px - 30px);
   }

   &_head {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 50px;
      row-gap: clamp(1.875rem, -4.375rem + 6.25vw, 3.125rem);
      // 50px > 30px
      height: 100%;

      @include desktop {
         row-gap: 30px;
      }

      @include tablet {
         row-gap: 24px;
      }

      &-title {
         color: #121212;
         text-align: center;
         font-size: 42px;
         font-size: clamp(2.25rem, 0.375rem + 1.875vw, 2.625rem);
         // 42px > 36px
         font-weight: 400;
         line-height: 48px;
         line-height: clamp(2.625rem, 0.75rem + 1.875vw, 3rem);
         // 48px > 42px
         letter-spacing: -0.84px;

         max-width: 1250px;
         max-width: clamp(56.25rem, -53.125rem + 109.375vw, 78.125rem);
         // 1250px > 900px

         @include desktop {
            color: #121212;
            font-size: 36px;
            line-height: normal;
            letter-spacing: -0.72px;

            max-width: 900px;
         }

         @include tablet {
            color: #121212;
            font-size: 28px;
            line-height: normal;
            letter-spacing: -0.56px;
         }
      }

      .list_page_tabs {
         flex: 1 1 auto;
         width: 100%;
         position: relative;
      }
   }


}