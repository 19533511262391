@import '../../../../scss/media';

.reviews_blockquote {
   position: relative;
   padding-bottom: 80px;
   padding-bottom: clamp(2.5rem, -10rem + 12.5vw, 5rem);
   // 80px > 40px

   @include desktop {
      padding-bottom: 40px;
   }

   @include tablet {
      padding-bottom: 30px;
   }

   @include mobile {
      padding-bottom: 0px;
      border-left: 1px solid #353535;
      border-top: 1px solid #353535;
      border-top-left-radius: 16px;
      padding-top: 14px;
      padding-left: 14px;
      padding-right: 19px;
   }

   .row {
      align-items: flex-end;
      row-gap: 24px;

      @include tablet {
         column-gap: 23px;
      }

      .col-xl-10 {
         @include tablet {
            width: 100%;
            max-width: calc(100% - 131px - 23px);
            padding-right: 0px;
         }

         @include mobile {
            max-width: 100%;
            padding-right: calc(var(--bs-gutter-x)* .5);
         }
      }

      .col-xl-2 {
         @include tablet {
            width: 100%;
            max-width: 131px;
            padding-left: 0px;
         }

         @include mobile {
            max-width: 100%;
            padding-left: calc(var(--bs-gutter-x)* .5);
         }
      }
   }

   &_content {
      position: relative;
      padding-right: 60px;
      padding-right: clamp(2.75rem, -2.25rem + 5vw, 3.75rem);
      // 60px > 44px
      height: 100%;

      @include desktop {
         padding-right: 44px;
      }

      @include tablet {
         padding-right: 0;
      }

      @include mobile {
         padding-right: 0;
      }

      &_it {
         display: flex;
         flex-direction: column;
         row-gap: 40px;
         row-gap: clamp(2rem, -0.5rem + 2.5vw, 2.5rem);
         // 40px > 32px
         padding-top: 80px;
         padding-top: clamp(2.5rem, -10rem + 12.5vw, 5rem);
         // 80px > 40px
         padding-right: 223px;
         padding-right: clamp(6.813rem, -28.813rem + 35.625vw, 13.938rem);
         // 223px > 109px
         padding-left: 142px;
         padding-left: clamp(6.813rem, -3.5rem + 10.313vw, 8.875rem);
         // 142px > 109px
         position: relative;
         height: 100%;

         @include desktop {
            row-gap: 32px;
            padding-top: 40px;
            padding-right: 109px;
            padding-left: 109px;
         }

         @include tablet {
            padding-top: 20px;
            padding-right: 63px;
            padding-left: 59px;
            row-gap: 12px;
         }

         @include mobile {
            padding-top: 0px;
            padding-left: 0px;
            padding-right: 0px;

         }

         &::before {
            content: '';
            display: block;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='62' height='46' viewBox='0 0 62 46' fill='none'%3E%3Cpath d='M27.3529 0L20.0588 26.5385V46H0V26.5385L18.2353 0H27.3529ZM62 0L54.7059 26.5385V46H34.6471V26.5385L52.8824 0H62Z' fill='%23353535'/%3E%3C/svg%3E");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center center;
            width: 62px;
            width: clamp(3rem, -1.375rem + 4.375vw, 3.875rem);
            // 62px > 48px
            height: 46px;
            height: clamp(2.25rem, -0.875rem + 3.125vw, 2.875rem);
            // 46px / 36px
            position: absolute;
            top: 80px;
            top: clamp(2.5rem, -10rem + 12.5vw, 5rem);
            // 80px > 40px
            left: 0;
            user-select: none;
            pointer-events: none;

            @include desktop {
               width: 48px;
               height: 36px;
               top: 40px;
            }

            @include tablet {
               width: 32px;
               height: 24px;
               top: 20px;
            }

            @include mobile {
               display: none;
            }
         }

         &::after {
            content: '';
            display: block;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='62' height='46' viewBox='0 0 62 46' fill='none'%3E%3Cpath d='M34.6471 46L41.9412 19.4615L41.9412 0H62L62 19.4615L43.7647 46H34.6471ZM3.8147e-06 46L7.29412 19.4615L7.29412 0H27.3529L27.3529 19.4615L9.11765 46H3.8147e-06Z' fill='%23353535'/%3E%3C/svg%3E");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center center;
            width: 62px;
            width: clamp(3rem, -1.375rem + 4.375vw, 3.875rem);
            // 62px > 48px
            height: 46px;
            height: clamp(2.25rem, -0.875rem + 3.125vw, 2.875rem);
            // 46px / 36px
            position: absolute;
            bottom: 0px;
            right: 0px;
            user-select: none;
            pointer-events: none;

            @include desktop {
               width: 48px;
               height: 36px;
            }

            @include tablet {
               width: 32px;
               height: 24px;
               right: 22px;
            }

            @include mobile {
               display: none;
            }
         }

         &-line {
            display: flex;
            align-items: flex-start;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 200px;
            height: clamp(11.063rem, 3.875rem + 7.187vw, 12.5rem);
            // 200px / 177px
            overflow: hidden;
            user-select: none;
            pointer-events: none;

            @include desktop {
               height: 177px;
            }

            @include mobile {
               display: none;
            }

            svg {
               width: 100%;
               max-width: 100%;
               height: auto;

               .svg-elem-1 {
                  stroke-dashoffset: 1525.131103515625px;
                  stroke-dasharray: 1525.131103515625px;
                  -webkit-transition: stroke-dashoffset 1.5s ease 0s;
                  transition: stroke-dashoffset 1.5s ease 0s;
               }
            }

         }

         &-title {
            color: #fff;
         }

         &-text {
            @include tablet {
               font-size: 14px;
               line-height: 20px;
               letter-spacing: 0.28px;
            }
         }
      }
   }

   &_author {
      display: flex;
      flex-direction: column;
      row-gap: 30px;
      row-gap: clamp(1.25rem, -1.875rem + 3.125vw, 1.875rem);
      // 30px > 20px

      @include desktop {
         row-gap: 20px;
      }

      @include mobile {
         flex-direction: row;
         column-gap: 20px;
      }

      &-image {
         display: block;
         width: 100%;
         max-width: 100%;
         height: auto;
         aspect-ratio: 1/1;
         overflow: hidden;
         border-radius: 10px;

         @include tablet {
            max-width: 75px;
         }

         @include mobile {
            height: 100%;
         }

         img {
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }

      &-info {

         @include tablet {
            font-size: 14px;
            line-height: normal;
            letter-spacing: 0.28px;
         }

         @include mobile {
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            max-width: calc(100% - 75px - 20px);
         }
      }
   }

   &-line {
      display: flex;
      align-items: flex-end;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: 200px;
      user-select: none;
      pointer-events: none;

      @include mobile {
         display: none;
      }


      svg {
         width: 100%;
         max-width: 100%;
         height: auto;

         .svg-elem-1 {
            stroke-dashoffset: 1869.130615234375px;
            stroke-dasharray: 1869.130615234375px;
            -webkit-transition: stroke-dashoffset 1.5s ease 0s;
            transition: stroke-dashoffset 1.5s ease 0s;
         }
      }

   }

   &.active {
      .reviews_blockquote-line {
         svg {
            .svg-elem-1 {
               stroke-dashoffset: 0;
            }
         }
      }


      .reviews_blockquote_content_it {
         svg {
            .svg-elem-1 {
               stroke-dashoffset: 0;
            }
         }
      }
   }
}