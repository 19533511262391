@import '../../../../scss/media';

.ReactModal__Overlay {
  position: absolute;
  z-index: unset !important;
}

.modal_menu_wrap {
  display: none;
  z-index: 120;

  @include mobile {
    display: flex;
    background: #353535;
    padding: 0px;
    opacity: 1;
    visibility: visible;
    top: 0px;
    left: 0px;
    justify-content: flex-end;
    user-select: none;
    pointer-events: none;

    &.ReactModal__Overlay--before-close {
      user-select: none;
      pointer-events: none;
    }

    &.ReactModal__Overlay--after-open {
      user-select: auto;
      pointer-events: all;
    }

    .modal_menu {
      padding: 50px 13px 50px 0px;
      width: 100%;
      max-width: calc(100vw - 35vw);
      height: 100%;
      position: unset;

      .close_menu {
        display: flex;
        position: absolute;
        right: 13px;
        top: 15px;
        opacity: 0;
        transition: .4s cubic-bezier(.15, .2, .1, 1);
        transition-delay: 0s;
        z-index: 2;
      }

      .city_block {
        visibility: hidden;
        opacity: 0;
        transition: .4s cubic-bezier(.15, .2, .1, 1);
        transition-delay: 0s;
      }

      nav {
        display: flex;
        align-items: center;
        width: 100%;
        transform: translateX(31px);
        transition: .8s cubic-bezier(.15, .2, .1, 1);
        position: relative;
        z-index: 2;
        margin-left: 20px;

        will-change: transform;

        ul {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          row-gap: 20px;

          max-height: calc(100%);
          overflow-y: auto;
          overflow-x: hidden;

          &::-webkit-scrollbar {
            width: 0px;
          }

          li {
            display: flex;

            &.drops {
              display: flex;
              flex-direction: column;

              button {
                display: flex;
                align-items: center;
                column-gap: 20px;

                .icon {
                  transition: .4s;
                }

                &.active {
                  .icon {
                    transform: rotate(180deg);
                  }
                }
              }

              .sub-menu {
                display: flex;
                flex-direction: column;
                row-gap: 14px;
                max-height: unset;
                overflow: unset;
                overflow: hidden;
                max-height: 0px;
                transition: .4s;

                li {
                  a {
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 400;
                    letter-spacing: 2%;
                  }
                }
              }
            }

            a,
            button {
              color: #FFF;
              font-size: 26px;
              font-weight: 400;
              line-height: 34px;
              letter-spacing: 0.52px;
            }
          }
        }
      }

      &-circle {
        display: flex;
        position: absolute;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        border-radius: 50%;
        background: #212121;
        filter: blur(60px);
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        animation: animMenuCircle 10s infinite forwards;
        user-select: none;
        pointer-events: none;
        will-change: transform;

        @keyframes animMenuCircle {
          0% {
            width: calc(100% - 40px);
            height: calc(100% - 40px);
          }

          50% {
            width: calc(100% - 200px);
            height: calc(100% - 200px);
          }

          100% {
            width: calc(100% - 40px);
            height: calc(100% - 40px);
          }
        }
      }

      &.ReactModal__Content--after-open {

        nav {
          transform: translateX(0px);
        }

        .close_menu {
          opacity: 1;
          transition: .4s;
          transition-delay: .6s;
        }

        .city_block {
          opacity: 1;
          visibility: visible;
          transition: .4s;
          transition-delay: .6s;
        }
      }

      &.ReactModal__Content--before-close {
        nav {
          transform: translateX(40px);
        }


        .close_menu {
          opacity: 0;
          transition: .4s;
          transition-delay: 0s;
        }

        .city_block {
          visibility: visible;
          opacity: 1;
          transition: .4s;
          transition-delay: 0s;
          z-index: 1;
        }
      }
    }
  }
}