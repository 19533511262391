@use 'sass:math';

$desktop: 1599px;
$tablet: 1199px;
$mobile: 767px;

@mixin desktop {
   @media screen and (max-width: $desktop) {
      @content;
   }
}

@mixin tablet {
   @media screen and (max-width: $tablet) {
      @content;
   }
}

@mixin mobile {
   @media screen and (max-width: $mobile) {
      @content;
   }
}

@mixin hover {
   @media (hover: hover) {
      &:hover {
         @content;
      }
   }

   @media (hover: none) {
      &:active {
         @content;
      }
   }
}

@function fl($max: 48, $min: 16) {
   $maxViewportWidth: 1920;
   $minViewportWidth: 1600;

   $maxSize: math.div($max, 16);
   $minSize: math.div($min, 16);
   $maxWidth: math.div($maxViewportWidth, 16);
   $minWidth: math.div($minViewportWidth, 16);

   $slope: math.div(($maxSize - $minSize), ($maxWidth - $minWidth));
   $yAxisIntersection: -$minWidth * $slope + $minSize;

   @return clamp(#{$minSize * 1rem},
      #{$yAxisIntersection * 1rem} + #{$slope * 100vw},
      #{$maxSize * 1rem});
}