@use '../../../scss/media';

.product_page_need {
   margin-top: 120px;
   margin-top: media.fl(120, 80);

   @include media.desktop {
      margin-top: 80px;
   }

   @include media.tablet {
      margin-top: 60px;
   }

   .col-12 {
      @include media.tablet {
         margin-bottom: 24px;
      }

      @include media.mobile {
         margin-bottom: 0px;
      }
   }

   &_head {
      @include media.tablet {
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column;
         row-gap: 16px;
      }
   }

   &_left {
      display: flex;
      flex-direction: column;

      >* {
         &:first-child {
            margin-top: 0px;
         }

         &:last-child {
            margin-bottom: 0px;
         }
      }

      @include media.mobile {
         margin-top: 16px;
      }

      &-icon {
         display: flex;
         justify-content: center;
         align-items: center;
         width: 100%;
         max-width: 60px;
         max-width: media.fl(60, 50);
         height: 60px;
         height: media.fl(60, 50);
         aspect-ratio: 1/1;
         overflow: hidden;

         @include media.desktop {
            max-width: 50px;
            height: 50px;
         }

         @include media.tablet {
            max-width: 40px;
            height: 40px;
         }

         svg,
         img {
            width: 100%;
            height: 100%;
            object-fit: contain;
         }
      }

      &-title {
         margin-top: 40px;
         text-align: left;

         @include media.tablet {
            margin-top: 0px;
            text-align: center;
         }
      }

      &-text {
         margin-top: 30px;
      }
   }

   &_right {
      background: #FBFBFB;
      padding: 30px;
      position: relative;
      border-radius: 10px;
      margin-top: 50px;
      margin-top: media.fl(50, 30);
      margin-bottom: 50px;
      margin-bottom: media.fl(50, 30);

      @include media.desktop {
         margin-left: 30px;
         margin-right: 30px;
         margin-top: 30px;
         margin-bottom: 30px;
      }

      @include media.tablet {
         margin: 0;
         padding: 0;
         background: unset;
         border-radius: unset;
      }

      @include media.mobile {
         margin-top: 24px;
      }

      &_content {
         display: flex;
         flex-direction: column;
         row-gap: 30px;
         padding: 50px;
         padding: media.fl(50, 30);
         border-radius: 10px;
         border: 1px solid #E8E8EB;
         background: #FFF;

         @include media.desktop {
            padding: 30px;
         }

         @include media.tablet {
            padding: 20px;
            row-gap: 20px;
         }

         &-text {
            p {
               margin-bottom: 20px;

               &:last-of-type {
                  margin-bottom: 0px;
               }

               @include media.tablet {
                  margin-bottom: 10px;
               }
            }
         }

         &-icon {
            display: flex;
            width: 100%;
            max-width: 100%;

            svg,
            img {
               max-width: 100%;
               width: 100%;
               height: auto;
            }
         }
      }
   }
}