@use '../../../scss/media';

.product_page_addition {
   margin-top: 120px;
   margin-top: media.fl(120, 80);
   margin-bottom: 50px;
   margin-bottom: media.fl(50, 30);

   @include media.desktop {
      margin-top: 80px;
      margin-bottom: 30px;
   }

   @include media.tablet {
      margin-top: 60px;
      margin-bottom: 0px;
   }

   &_head {
      &-title {
         text-align: left;

         @include media.tablet {
            margin-bottom: 24px;
         }

         @include media.mobile {
            margin-bottom: 0px;
         }
      }
   }

   &_body {
      position: relative;
      margin-top: 120px;
      margin-top: media.fl(120, 100);
      background: #FBFBFB;
      border-radius: 10px;
      padding: 30px;

      @include media.desktop {
         margin-top: 100px;
      }

      @include media.tablet {
         margin-top: 0px;
         padding: 0px;
         background: unset;
         border-radius: unset;
      }

      @include media.mobile {
         margin-top: 24px;
      }

      &_content {
         display: flex;
         column-gap: 50px;
         column-gap: media.fl(50, 40);
         align-items: flex-start;
         justify-content: flex-start;
         border-radius: 10px;
         border: 1px solid #E8E8EB;
         background: #FFF;
         padding: 49px;
         overflow: hidden;

         @include media.desktop {
            column-gap: 40px;
         }

         @include media.tablet {
            padding: 19px;
            flex-direction: column;
            row-gap: 20px;
         }

         &-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 60px;
            max-width: media.fl(60, 50);
            height: 60px;
            height: media.fl(60, 50);
            aspect-ratio: 1/1;
            overflow: hidden;

            @include media.desktop {
               max-width: 50px;
               height: 50px;
            }

            @include media.tablet {
               max-width: 40px;
               height: 40px;
            }

            svg,
            img {
               width: 100%;
               height: 100%;
               object-fit: contain;
            }
         }

         &_right {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            max-width: 1100px;
            max-width: media.fl(1100, 970);

            @include media.desktop {
               max-width: 970px;
            }

            @include media.tablet {
               max-width: 100%;
               row-gap: 10px;
            }

            &-title {
               color: #121212;
               font-size: 26px;
               font-weight: 400;
               line-height: 34px;
               letter-spacing: 0.52px;

               @include media.tablet {
                  font-size: 18px;
                  line-height: normal;
                  letter-spacing: 0.36px;
               }
            }
         }
      }


   }

   .product_page_addition_head-text {
      @include media.mobile {
         margin-top: 16px;
      }
   }
}