@import '../../../scss/media';

.ReactModal__Overlay {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100%;
   position: fixed;
   top: 0;
   left: 0;
   z-index: 999;
   background: rgba(0, 0, 0, .2);
   padding: 20px;
   outline: none;
   opacity: 0;
   visibility: hidden;
   transition: .4s;

   @include desktop {
      padding: 20px;
   }

   @include tablet {
      padding: 20px 13px;
   }

   &--after-open {
      opacity: 1;
      visibility: visible;
   }

   &--before-close {
      opacity: 0;
      visibility: hidden;
   }

   &.custom_overlay {
      z-index: 122;
   }
}

.ReactModal__Content {
   display: flex;
   height: auto;
   max-height: 100%;
   border-radius: 10px;
   overflow: hidden;
   outline: none;
   width: 100%;
   max-width: 1115px;
   max-width: clamp(56.25rem, -10.938rem + 67.188vw, 69.688rem);
   // 1115px > 900px

   @include desktop {
      max-width: 900px;
   }
}

.remodal {
   &.error {
      .title_block {
         max-width: 430px;
         max-width: clamp(20.625rem, -10.625rem + 31.25vw, 26.875rem);
         // 430px > 330px

         @include desktop {
            max-width: 330px;
         }

         @include tablet {
            max-width: 300px;
         }

         @include mobile {
            max-width: 400px;
         }
      }
   }
}