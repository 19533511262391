@import '../../../scss/media';

.product_modal {
   display: flex;
   width: 100%;
   max-width: 1115px;
   max-width: clamp(56.25rem, -10.938rem + 67.188vw, 69.688rem);
   // 1115px > 900px
   background: #fff;
   border-radius: 10px;
   max-height: 100%;
   position: relative;

   @include desktop {
      max-width: 900px;
   }

   @include mobile {
      flex-direction: column;
      background: transparent;
   }

   &_left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      row-gap: 50px;
      padding: 60px 35px 50px 35px;
      padding-top: clamp(2.5rem, -3.75rem + 6.25vw, 3.75rem);
      // 60px > 48px
      padding-bottom: clamp(2.5rem, -0.625rem + 3.125vw, 3.125rem);
      // 50px > 40px
      width: 100%;
      max-width: 300px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      background: rgb(255, 161, 111);
      position: relative;
      overflow: hidden;

      @include desktop {
         padding: 35px 35px 40px 35px;
      }

      @include tablet {
         padding: 34px 30px 20px 30px;
         max-width: 243px;
         border-radius: unset;
         background: radial-gradient(50% 49.96% at 50% 50.04%, #F2651A 15.5%, #FFA16F 100%);
         overflow: visible;
      }

      @include mobile {
         max-width: 100%;
         flex-direction: row;
         padding: 14px 18px 14px 20px;
         column-gap: 20px;
         border-top-left-radius: 10px;
         border-top-right-radius: 10px;
      }

      &.is_left_btn {
         padding-bottom: 76px;
         padding-bottom: clamp(3.75rem, -1.25rem + 5vw, 4.75rem);
         // 76px > 55px

         @include desktop {
            padding-bottom: 60px;
         }

         @include tablet {
            padding-bottom: 20px;
         }

         @include mobile {
            padding-bottom: 14px;
         }
      }

      &-circle {
         display: block;
         background: rgba(242, 101, 26, 1);
         width: calc(100% - 80px);
         height: calc(100% - 80px);
         position: absolute;
         top: 50%;
         left: 50%;
         translate: -50% -50%;
         border-radius: 50%;
         user-select: none;
         pointer-events: none;
         will-change: transform;
         filter: blur(70px);
         opacity: 0;
         transition: opacity .6s;

         @include tablet {
            width: calc(100% - 40px);
            height: calc(100% - 40px);
         }

         @include mobile {
            width: calc(100% - 20px);
            height: calc(100% - 20px);
         }
      }

      &-circle_static {
         display: block;
         display: block;
         background: rgba(242, 101, 26, 1);
         width: calc(100% - 80px);
         height: calc(100% - 80px);
         position: absolute;
         top: 50%;
         left: 50%;
         translate: -50% -50%;
         border-radius: 50%;
         user-select: none;
         pointer-events: none;
         will-change: transform;
         filter: blur(70px);

         @include tablet {
            width: calc(100% - 40px);
            height: calc(100% - 40px);
         }

         @include mobile {
            width: calc(100% - 20px);
            height: calc(100% - 20px);
         }
      }

      &_mobile {
         display: flex;
         flex-direction: column;
         row-gap: 45px;
         justify-content: space-between;
         align-items: flex-start;
         height: 100%;
         padding: 10px 0;
         width: 100%;
         max-width: calc(100% - 110px - 20px);
         position: relative;
         z-index: 2;

         &-title {
            color: #FFF;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.36px;
         }

         &_bot {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            justify-content: flex-start;
            align-items: flex-start;
         }
      }

      &-image {
         display: flex;
         justify-content: center;
         align-items: center;
         width: 100%;
         max-width: 220px;
         max-width: clamp(12.5rem, 6.25rem + 6.25vw, 13.75rem);
         // 220px > 200px
         height: auto;
         aspect-ratio: 1/1;
         overflow: hidden;
         position: relative;
         z-index: 2;
         padding-left: 20px;
         padding-right: 20px;

         @include desktop {
            max-width: 200px;
         }

         @include tablet {
            max-width: 110px;
            padding-left: 0px;
            padding-right: 0px;
         }

         @include mobile {
            max-width: 108px;
         }

         img {
            width: 100%;
            height: 100%;
            object-fit: contain;
         }
      }

      &-btn {
         display: inline-flex;
         justify-content: center;
         align-items: center;
         column-gap: 10px;
         padding: 7px 13px 7px 16px;
         padding-top: clamp(0.313rem, -0.313rem + 0.625vw, 0.438rem);
         // 7px > 5px
         padding-right: clamp(0.75rem, 0.438rem + 0.313vw, 0.813rem);
         // 13px > 12px
         padding-bottom: clamp(0.313rem, -0.313rem + 0.625vw, 0.438rem);
         // 7px > 5px
         padding-left: clamp(0.75rem, -0.5rem + 1.25vw, 1rem);
         // 16px > 12px
         border-radius: 50px;
         background: #F5F5F7;

         color: #121212;
         text-align: center;
         font-family: "IBM Plex Mono";
         font-size: 18px;
         font-size: clamp(0.875rem, -0.375rem + 1.25vw, 1.125rem);
         // 18px > 14px
         font-weight: 400;
         line-height: 24px;
         line-height: clamp(1.25rem, 0rem + 1.25vw, 1.5rem);
         // 24px > 20px
         letter-spacing: 0.36px;
         text-transform: uppercase;
         position: relative;
         z-index: 2;
         transition: .4s;

         @include hover {
            opacity: .7;
         }

         @include desktop {
            font-size: 14px;
            line-height: normal;
            padding: 5px 12px;
         }

         @include tablet {
            font-size: 12px;
            line-height: normal;
            letter-spacing: 0.24px;
            padding: 5px 12px 6px 12px;
         }
      }

      &-icon {
         display: flex;
         width: 14px;
         width: clamp(0.625rem, -0.625rem + 1.25vw, 0.875rem);
         // 14px > 10px
         height: 14px;
         height: clamp(0.625rem, -0.625rem + 1.25vw, 0.875rem);
         // 14px > 10px

         @include desktop {
            width: 10px;
            height: 10px;
         }

         svg {
            width: 100%;
            height: 100%;
         }
      }

      &_bot {
         display: flex;
         flex-direction: column;
         row-gap: 20px;
         row-gap: clamp(0.938rem, -0.625rem + 1.563vw, 1.25rem);
         // 20px > 15px
         justify-content: flex-start;
         align-items: center;

         @include desktop {
            row-gap: 15px;
         }

         @include tablet {
            row-gap: 10px;
         }
      }
   }

   &_content {
      padding: 50px 15px 50px 50px;
      padding-top: clamp(2.5rem, -0.625rem + 3.125vw, 3.125rem);
      // 50px > 40px
      padding-bottom: clamp(2.5rem, -0.625rem + 3.125vw, 3.125rem);
      // 50px > 40px
      padding-left: clamp(2.5rem, -0.625rem + 3.125vw, 3.125rem);
      // 50px > 40px
      width: 100%;
      max-width: calc(100% - 300px);
      background: #fff;

      max-height: 100%;
      overflow-y: hidden;

      @include desktop {
         padding: 40px 15px 40px 40px;
      }

      @include tablet {
         padding: 20px 5px 20px 20px;
         max-width: calc(100% - 243px);
      }

      @include mobile {
         max-width: 100%;
         padding-right: 10px;
      }

      &.is_left_btn {
         padding-bottom: 76px;
         padding-bottom: clamp(3.75rem, -1.25rem + 5vw, 4.75rem);
         // 76 px > 55px

         @include desktop {
            padding-bottom: 60px;
         }

         @include tablet {
            padding-bottom: 20px;
         }

         @include mobile {
            padding-bottom: 20px;
         }
      }

      &_wrap {
         display: flex;
         flex-direction: column;
         row-gap: 20px;
         row-gap: clamp(0.938rem, -0.625rem + 1.563vw, 1.25rem);
         // 20px > 15px
         max-height: 100%;
         overflow-y: auto;
         padding-right: 35px;
         padding-right: clamp(1.563rem, -1.563rem + 3.125vw, 2.188rem);
         // 35px > 25px

         @include desktop {
            row-gap: 15px;
            padding-right: 25px;
         }

         @include tablet {
            row-gap: 14px;
            padding-right: 10px;
         }
      }

      &-title {
         color: #121212;
         font-size: 26px;
         font-size: clamp(1.25rem, -0.625rem + 1.875vw, 1.625rem);
         // 26px > 20px
         font-weight: 400;
         line-height: 34px;
         line-height: clamp(1.75rem, -0.125rem + 1.875vw, 2.125rem);
         // 34px > 28px
         letter-spacing: 0.52px;
         padding-right: 50px;

         @include desktop {
            font-size: 20px;
            line-height: 28px;
            letter-spacing: 0.4px;
         }

         @include tablet {
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.36px;
         }
      }

      &-text {
         display: flex;
         flex-direction: column;
         row-gap: 20px;
         row-gap: clamp(0.938rem, -0.625rem + 1.563vw, 1.25rem);
         // 20px > 15px

         @include desktop {
            row-gap: 15px;
         }

         @include tablet {
            line-height: 22px;
            letter-spacing: 0.28px;
            row-gap: 10px;
         }

         ul {
            list-style: disc;
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
         }
      }
   }

   &-close {
      display: flex;
      position: absolute;
      top: 30px;
      top: clamp(1.563rem, 0rem + 1.563vw, 1.875rem);
      // 30px > 25px
      right: 30px;
      right: clamp(1.563rem, 0rem + 1.563vw, 1.875rem);
      // 30px > 25px
      cursor: pointer;
      width: 38px;
      width: clamp(1.875rem, -0.625rem + 2.5vw, 2.375rem);
      // 38px > 30px
      height: 38px;
      height: clamp(1.875rem, -0.625rem + 2.5vw, 2.375rem);
      // 38px > 30px
      transition: .4s;

      @include desktop {
         top: 25px;
         right: 25px;
         width: 30px;
         height: 30px;
      }

      @include tablet {
         top: 20px;
      }

      @include mobile {
         position: unset;
         margin: 0 auto;
         margin-bottom: 12px;
      }

      @include hover {
         opacity: .7;
      }

      svg {
         width: 100%;
         height: 100%;
      }
   }

   .dwn_pdf {
      display: flex;
      align-items: center;
      column-gap: 10px;
      position: absolute;
      z-index: 2;
      bottom: 32px;
      bottom: clamp(1.438rem, -1.375rem + 2.813vw, 2rem);
      // 32px > 23px

      @include desktop {
         bottom: 23px;
      }

      @include tablet {
         position: relative;
         bottom: unset;
      }

      span {
         color: #FFF;
         font-size: 18px;
         font-size: clamp(1rem, 0.375rem + 0.625vw, 1.125rem);
         // 18px > 16px
         font-weight: 400;
         line-height: 24px;
         line-height: clamp(1.375rem, 0.75rem + 0.625vw, 1.5rem);
         // 24px > 22px
         text-transform: lowercase;
         position: relative;

         @include desktop {
            font-size: 16px;
            line-height: 22px;
         }

         @include tablet {
            font-size: 14px;
         }

         &::after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background: #fff;
            position: absolute;
            left: 0px;
            bottom: 0px;
            transform: scaleX(1);
            transform-origin: 0% 0%;

            transition: transform .4s;
         }
      }

      .icon {
         display: flex;
         justify-content: center;
         align-items: flex-end;
         position: relative;
         width: 14px;
         height: 14px;
         overflow: hidden;

         .svg_visible {
            width: 100%;
            height: 100%;

            &-arrow {
               transform: translateY(0%);
               animation: arrowBot 1s forwards infinite ease-in-out;
            }
         }

         .svg_hidden {
            width: 100%;
            height: 100%;
            position: absolute;

            &-arrow {
               transform: translateY(-100%);
               animation: arrowTop 1s forwards infinite ease-in-out;
            }

            &-bot {
               opacity: 0;
               visibility: hidden;
            }
         }
      }

      @include hover {
         span {
            &::after {
               transform: scaleX(0);
               transform-origin: 100% 0%;
            }
         }
      }

      @keyframes arrowBot {
         0% {
            transform: translateY(0%);
         }

         80% {
            transform: translateY(100%);
         }

         100% {
            transform: translateY(100%);
         }
      }

      @keyframes arrowTop {
         10% {
            transform: translateY(-100%);
         }

         80% {
            transform: translateY(0%);
         }

         100% {
            transform: translateY(0%);
         }
      }
   }
}