@import '../../scss/media';

@property --angle-1 {
   syntax: '<angle>';
   initial-value: 0deg;
   inherits: true;
}

@property --angle-2 {
   syntax: '<angle>';
   initial-value: 90deg;
   inherits: true;
}

@property --angle-3 {
   syntax: '<angle>';
   initial-value: 180deg;
   inherits: true;
}

@property --angle-4 {
   syntax: '<angle>';
   initial-value: 270deg;
   inherits: true;
}

:root {
   --d: 3500ms;
   --angle-1: 0deg;
   --angle-2: 90deg;
   --angle-3: 180deg;
   --angle-4: 270deg;
   --c1: #0970FF;
   --c2: rgba(168, 239, 255, 0.1);
}

.advantages_card {
   row-gap: 20px;

   @include desktop {
      overflow: visible;
   }

   @include tablet {
      row-gap: 10px;
   }

   .swiper-slide {
      @include desktop {
         max-width: 350px;
         height: auto;
      }

      @include tablet {
         max-width: 305px;
      }

      @include mobile {
         max-width: 320px;
      }

      &:nth-child(odd) {
         .advantages_card_item.black {
            border-image: conic-gradient(from var(--angle-1), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30;
         }
      }

      &:nth-child(even) {
         .advantages_card_item.black {
            border-image: conic-gradient(from var(--angle-3), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30;
         }
      }
   }

   .col-xl-3 {
      &:nth-child(odd) {
         .advantages_card_item.black {
            border-image: conic-gradient(from var(--angle-1), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30;
         }
      }

      &:nth-child(even) {
         .advantages_card_item.black {
            border-image: conic-gradient(from var(--angle-3), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30;
         }
      }
   }


   &_item {
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      border: 1px solid #E8E8EB;
      background: #fff;

      padding: 49px;
      padding: clamp(2.438rem, -0.688rem + 3.125vw, 3.063rem);
      // 49px > 39px
      height: 100%;


      @include desktop {
         padding: 39px;
         padding: clamp(1.188rem, -5.063rem + 8.333vw, 2.438rem);
         // 39px / 19px
      }

      @include tablet {
         padding: 19px;
      }


      &.black {
         border-radius: 10px;
         border: unset;
         border: 1px solid transparent;
         background: #252525;
         position: relative;
         animation: borderRotate var(--d) linear infinite forwards;


         @keyframes borderRotate {

            100% {
               --angle-1: 360deg;
               --angle-2: 450deg;
               --angle-3: 540deg;
               --angle-4: 630deg;
            }
         }
      }

      >* {
         &:first-child {
            margin-top: 0px;
         }

         &:last-child {
            margin-bottom: 0px;
         }
      }

      &-icon {
         display: flex;
         justify-content: center;
         align-items: center;
         width: 100%;
         max-width: 60px;
         max-width: clamp(3.125rem, 0rem + 3.125vw, 3.75rem);
         // 60px > 50px
         height: 60px;
         height: clamp(3.125rem, 0rem + 3.125vw, 3.75rem);
         // 60px > 50px
         aspect-ratio: 1/1;
         overflow: hidden;

         margin-bottom: 10px;

         @include desktop {
            max-width: 50px;
            max-width: clamp(2.5rem, -0.625rem + 4.167vw, 3.125rem);
            // 50px > 40px
            height: 50px;
            height: clamp(2.5rem, -0.625rem + 4.167vw, 3.125rem);
            // 50px > 40px
         }

         @include tablet {
            max-width: 40px;
            height: 40px;
         }


         svg {
            width: 100%;
            height: 100%;
         }
      }

      &-title {
         margin-top: 20px;
         margin-top: clamp(0.625rem, -2.5rem + 3.125vw, 1.25rem);
         // 20px > 10px

         color: #121212;
         font-size: 26px;
         font-size: clamp(1.25rem, -0.625rem + 1.875vw, 1.625rem);
         // 26px ? 20px
         font-weight: 400;
         line-height: 34px;
         line-height: clamp(1.75rem, -0.125rem + 1.875vw, 2.125rem);
         // 34px > 28px
         letter-spacing: 0.52px;

         @include desktop {
            margin-top: 10px;

            font-size: 20px;
            font-size: clamp(1.125rem, 0.5rem + 0.833vw, 1.25rem);
            // 20px > 18px
            line-height: 28px;
            letter-spacing: 0.4px;
         }

         @include tablet {
            font-size: 18px;
            line-height: normal;
            letter-spacing: 0.36px;
         }

         &.white {
            color: #fff;
         }
      }

      .typical_text {
         margin-top: 20px;
         margin-top: clamp(0.625rem, -2.5rem + 3.125vw, 1.25rem);
         // 20px > 10px

         @include desktop {
            margin-top: 10px;
         }
      }
   }
}