@import '../../../scss/media';

.rev_client {
   &_wrap {
      display: flex;
      flex-direction: column;
      row-gap: 40px;
      border-radius: 10px;
      background: radial-gradient(50% 50% at 50% 50%, #036CFE 12.86%, #7DB3FE 100%);
      padding: 30px 30px 40px 30px;

      @include desktop {
         padding-bottom: 30px;
         row-gap: 30px;
      }

      @include mobile {
         padding: 20px 13px;
         margin-left: -13px;
         margin-right: -13px;
         border-radius: 0px;
      }

      &_sw {
         width: 100%;
         max-width: 100%;

         @include mobile {
            overflow: visible;
         }

         &_item {
            display: flex;
            column-gap: 20px;
            max-width: 100%;
            height: auto;

            @include tablet {
               column-gap: 0px;
               row-gap: 0px;
               flex-direction: column;
            }

            @include mobile {
               row-gap: 7px;
            }

            &-text_block {
               width: 100%;
               max-width: 65%;
               height: 100%;

               @include tablet {
                  max-width: 100%;
                  flex: 1 1 auto;
                  height: auto;
               }

               @include mobile {
                  padding: 20px;
                  background: #fff;
                  border: 1px solid #E8E8EB;
                  border-radius: 10px;
                  max-height: 328px;
                  flex: unset;
               }

               &-it {
                  padding: 50px;
                  border-radius: 10px;
                  border: 1px solid #E8E8EB;
                  background: #FFF;
                  height: 100%;

                  @include tablet {
                     max-width: 100%;
                     border-radius: 0px;
                     border-top-left-radius: 10px;
                     border-top-right-radius: 10px;
                     border-bottom: unset;
                     padding: 30px 30px 20px 30px;
                     height: 100%;
                     line-height: 24px;
                  }

                  @include mobile {
                     padding: 0 20px 0 0;
                     border-radius: 0px;
                     overflow-y: auto;
                     background: transparent;
                     border: unset;

                     &::-webkit-scrollbar {
                        width: 3px;
                        border-radius: 10px;
                     }

                     &::-webkit-scrollbar-track {
                        background: #E8E8EB;
                     }

                     &::-webkit-scrollbar-thumb {
                        background: #006AFE;
                        border-radius: 10px;
                     }
                  }
               }
            }



            &_info {
               display: flex;
               flex-direction: column;
               justify-content: center;
               align-items: center;
               width: 100%;
               max-width: calc(35% - 20px);
               border-radius: 10px;
               border: 1px solid #E8E8EB;
               background: #FFF;
               padding: 40px;
               row-gap: 30px;

               @include tablet {
                  max-width: 100%;
                  border-radius: 0px;
                  border-bottom-left-radius: 10px;
                  border-bottom-right-radius: 10px;
                  border-top: unset;
                  padding: 20px 30px 30px 30px;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: flex-start;
                  column-gap: 30px;
               }

               @include mobile {
                  flex-direction: column;
                  padding: 20px;
                  border-radius: 10px;
                  justify-content: flex-start;
                  align-items: flex-start;

                  row-gap: 20px;
               }

               &-avatar {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                  max-width: 120px;
                  height: 120px;
                  aspect-ratio: 1/1;
                  overflow: hidden;
                  border-radius: 50%;

                  @include tablet {
                     max-width: 100px;
                     height: 100px;
                  }

                  @include mobile {
                     max-width: 50px;
                     height: 50px;
                  }

                  img {
                     width: 100%;
                     height: 100%;
                     object-fit: cover;
                  }
               }

               &_content {
                  display: flex;
                  flex-direction: column;
                  row-gap: 20px;
                  align-items: center;

                  @include tablet {
                     row-gap: 10px;
                     align-items: flex-start;
                     width: 100%;
                     max-width: calc(100% - 30px - 100px);
                     height: 100%;
                     justify-content: center;
                  }

                  @include mobile {
                     max-width: 100%;
                     height: auto;
                     justify-content: flex-start;
                  }

                  &-name {
                     color: #121212;
                     text-align: center;

                     font-size: 26px;
                     font-weight: 400;
                     line-height: 34px;
                     letter-spacing: 0.52px;

                     @include tablet {
                        font-size: 18px;
                        line-height: 20px;
                        letter-spacing: 0.36px;
                     }
                  }

                  &-descr {
                     @include tablet {
                        text-align: left !important;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0.28px;
                     }
                  }

                  &-job {
                     @include tablet {
                        text-align: left !important;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0.28px;
                     }
                  }
               }


            }
         }
      }

      &_btns {
         display: flex;
         column-gap: 20px;

         @include tablet {
            column-gap: 10px;
         }

         @include mobile {
            justify-content: space-between;
         }

         &-prev {
            display: flex;
            position: unset;
            padding: 0px;
            margin: 0px;

            transition: .4s;

            &::after {
               display: none;
            }

            &.swiper-button-disabled {
               opacity: 1;

               rect {
                  stroke: #C9C9C9;
               }

               path {
                  stroke: #C9C9C9;
               }
            }
         }

         &-next {
            display: flex;
            position: unset;
            padding: 0px;
            margin: 0px;

            transition: .4s;

            &::after {
               display: none;
            }

            &.swiper-button-disabled {
               opacity: 1;

               rect {
                  stroke: #C9C9C9;
               }

               path {
                  stroke: #C9C9C9;
               }
            }
         }

         .swiper-pagination {
            display: flex;
            align-items: center;
            position: unset;
            padding: 0px;
            margin: 0px;
            width: auto;
            height: auto;
            border-radius: 12px;
            border: 1px solid #FFF;
            background: #FFF;
            padding: 0 19px;
            column-gap: 10px;
            background: transparent;

            @include tablet {
               border-radius: 4px;
            }

            &-bullet {
               display: flex;
               width: 10px;
               height: 10px;
               aspect-ratio: 1/1;
               margin: 0px;
               padding: 0px;
               background: #fff;
               opacity: .5;
               margin: 0 !important;

               transition: .4s;

               @include tablet {
                  width: 6px;
                  height: 6px;
               }

               &-active {
                  background: #fff;
                  opacity: 1;
               }
            }
         }
      }
   }
}