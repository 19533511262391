@import '../../../scss/media';

.services_page_wrap_list {
   position: relative;
   width: 100%;
   overflow: hidden;
   height: 100%;

   @include tablet {
      overflow: visible;
   }

   .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
   }
}

.services_page_list {
   display: flex;
   flex-direction: column;
   row-gap: 50px;
   row-gap: clamp(1.875rem, -4.375rem + 6.25vw, 3.125rem);
   // 50px > 30px
   margin-top: 30px;
   margin-top: clamp(1.25rem, -1.875rem + 3.125vw, 1.875rem);
   // 30px > 20px

   @include desktop {
      margin-top: 20px;
      row-gap: 30px;
   }

   @include tablet {
      margin-top: 6px;
      row-gap: 0px;
   }

   &_item {
      padding: 49px;
      padding: clamp(2.438rem, -0.688rem + 3.125vw, 3.063rem);
      // 49px > 39px
      border-radius: 10px;
      border: 1px solid #E8E8EB;
      background: #FFF;


      @include desktop {
         padding: 39px;
      }

      @include tablet {
         padding: 24px 0;
         border: unset;
         border-radius: unset;
         position: relative;
      }

      &::after {
         @include tablet {
            content: '';
            display: block;
            width: calc(100% + 26px);
            height: 1px;
            background: #E8E8EB;
            position: absolute;
            top: 0;
            left: -13px;
         }
      }

      &:last-child {

         @include tablet {
            padding-bottom: 0px;
         }
      }

      .row {
         @include mobile {
            row-gap: 24px;
         }
      }

      &_left {
         display: flex;
         flex-direction: column;
         row-gap: 50px;
         row-gap: clamp(1.875rem, -4.375rem + 6.25vw, 3.125rem);
         // 50px > 30px

         @include desktop {
            row-gap: 30px;
         }

         @include tablet {
            row-gap: 24px;
         }

         &-title {
            color: #121212;
            font-size: 26px;
            font-size: clamp(1.25rem, -0.625rem + 1.875vw, 1.625rem);
            // 26px > 20px
            font-weight: 400;
            line-height: 34px;
            line-height: clamp(1.75rem, -0.125rem + 1.875vw, 2.125rem);
            // 34px > 28px
            letter-spacing: 0.52px;

            @include desktop {
               font-size: 20px;
               line-height: 28px;
               letter-spacing: 0.4px;
            }

            @include tablet {
               font-size: 16px;
               line-height: 22px;
               letter-spacing: 0.32px;
            }
         }

         &_list {
            display: flex;
            flex-direction: column;
            row-gap: 40px;
            row-gap: clamp(1.875rem, -1.25rem + 3.125vw, 2.5rem);
            // 40px > 30px

            @include desktop {
               row-gap: 30px;
            }

            @include tablet {
               row-gap: 24px;
            }

            &_it {
               display: flex;
               column-gap: 50px;
               column-gap: clamp(1.875rem, -4.375rem + 6.25vw, 3.125rem);
               // 50px > 30px

               @include desktop {
                  column-gap: 30px;
               }

               @include tablet {
                  column-gap: 20px;
               }

               &-icon {
                  display: flex;
                  width: 100%;
                  max-width: 60px;
                  max-width: clamp(3.125rem, 0rem + 3.125vw, 3.75rem);
                  // 60px > 50px
                  height: 60px;
                  height: clamp(3.125rem, 0rem + 3.125vw, 3.75rem);
                  // 60px > 50px
                  aspect-ratio: 1/1;
                  overflow: hidden;

                  @include desktop {
                     max-width: 50px;
                     height: 50px;
                  }

                  @include tablet {
                     max-width: 40px;
                     height: 40px;
                  }

                  svg,
                  img {
                     width: 100%;
                     height: 100%;
                     object-fit: contain;
                  }
               }

               &_info {
                  display: flex;
                  flex-direction: column;
                  row-gap: 20px;
                  row-gap: clamp(0.625rem, -2.5rem + 3.125vw, 1.25rem);
                  // 20px > 10px
                  width: 100%;
                  max-width: calc(100% - 60px - 50px);
                  max-width: calc(100% - clamp(3.125rem, 0rem + 3.125vw, 3.75rem) - clamp(1.875rem, -4.375rem + 6.25vw, 3.125rem));
                  // 60px > 50px
                  // 50px > 30px

                  @include desktop {
                     row-gap: 10px;
                     max-width: calc(100% - 50px - 30px);
                  }

                  @include tablet {
                     row-gap: 6px;
                     max-width: calc(100% - 40px - 20px);
                  }

                  &-title {
                     color: #121212;
                     font-size: 26px;
                     font-size: clamp(1.25rem, -0.625rem + 1.875vw, 1.625rem);
                     // 26px > 20px
                     font-weight: 400;
                     line-height: 34px;
                     line-height: clamp(1.75rem, -0.125rem + 1.875vw, 2.125rem);
                     // 34px > 28px
                     letter-spacing: 0.52px;

                     @include desktop {
                        font-size: 20px;
                        line-height: 28px;
                        letter-spacing: 0.4px;
                     }

                     @include tablet {
                        font-size: 16px;
                        line-height: 22px;
                        letter-spacing: 0.32px;
                     }
                  }

                  &-text {
                     display: flex;
                     flex-direction: column;
                     row-gap: 10px;

                     b {
                        color: #006AFE;
                        font-weight: 500;
                     }

                     @include desktop {
                        row-gap: 8px;
                     }

                     @include tablet {
                        row-gap: 5px;
                     }

                     ul {
                        display: flex;
                        flex-direction: column;
                        row-gap: 10px;
                        list-style-type: disc;
                        padding-left: 20px;

                        @include desktop {
                           row-gap: 8px;
                        }

                        @include tablet {
                           row-gap: 5px;
                        }
                     }
                  }
               }
            }
         }
      }

      .services_page_list_item_wrap {
         display: flex;
         flex-direction: column;
         align-items: center;
         row-gap: 40px;
         row-gap: clamp(1.5rem, -3.5rem + 5vw, 2.5rem);
         // 40px > 24px

         @include desktop {
            row-gap: 24px;
         }

         .btn {
            svg {
               .second_line {
                  opacity: 1;
                  transition: .4s;
               }
            }
         }
      }

      &_table {
         display: flex;
         flex-wrap: wrap;
         border-radius: 10px;
         border: 1px solid #006AFE;
         width: 100%;
         max-width: 100%;
         max-height: 400px;
         overflow: hidden;

         transition: .4s;

         &.active {
            max-height: 100%;
         }

         &.active+.btn {

            svg {
               .second_line {
                  opacity: 0;
               }
            }
         }

         &_head {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            max-width: 100%;
            border-bottom: 1px solid #006AFE;

            &-it {
               display: flex;
               justify-content: center;
               align-items: center;
               width: 100%;
               max-width: 50%;
               padding: 16px 50px;
               padding: clamp(0.625rem, -1.25rem + 1.875vw, 1rem) clamp(0.625rem, -11.875rem + 12.5vw, 3.125rem);
               // 16px > 10px
               // 50px > 10px

               color: #006AFE;
               text-align: center;
               font-size: 18px;
               font-size: clamp(1rem, 0.375rem + 0.625vw, 1.125rem);
               // 18px > 16px
               font-weight: 400;
               line-height: 26px;
               line-height: clamp(1.5rem, 0.875rem + 0.625vw, 1.625rem);
               // 26px > 24px
               letter-spacing: 0.36px;
               hyphens: auto;

               border-right: 1px solid #006AFE;

               &:last-child {
                  border: unset;
               }

               @include desktop {
                  font-size: 16px;
                  line-height: 24px;
                  letter-spacing: 0.32px;

                  padding: 10px;
               }

               @include tablet {
                  font-size: 14px;
                  line-height: 22px;
                  letter-spacing: 0.28px;
                  text-transform: lowercase;
               }

               &.half {
                  max-width: 50%;
               }

               &.full {
                  max-width: 100%;
               }
            }
         }

         &_body {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            &-it {
               display: flex;
               justify-content: center;
               align-items: center;
               width: 100%;
               max-width: 50%;
               padding: 10px 50px;
               padding: 10px clamp(0.625rem, -11.875rem + 12.5vw, 3.125rem);
               // 50px > 10px

               color: #006AFE;
               text-align: center;
               font-size: 18px;
               font-size: clamp(1rem, 0.375rem + 0.625vw, 1.125rem);
               // 18px > 16px
               font-weight: 400;
               line-height: 26px;
               line-height: clamp(1.5rem, 0.875rem + 0.625vw, 1.625rem);
               // 26px > 24px
               letter-spacing: 0.36px;
               text-transform: lowercase;

               border-right: 1px solid #006AFE;
               border-top: 1px solid #006AFE;

               &:nth-child(even) {
                  border-right: unset;
               }

               &:first-child {
                  border-top: unset;
               }

               &:nth-of-type(2) {
                  border-top: unset;
               }

               @include desktop {
                  padding: 10px;

                  font-size: 16px;
                  line-height: 24px;
                  letter-spacing: 0.32px;
               }

               @include tablet {
                  font-size: 14px;
                  line-height: 22px;
                  letter-spacing: 0.28px;
               }
            }
         }
      }

      &.col_text {
         .services_page_list_item_table_body {
            &-it {
               padding: 10px 30px;
               padding: 10px clamp(0.625rem, -5.625rem + 6.25vw, 1.875rem);
               // 30px > 10px

               @include desktop {
                  padding: 10px;
               }

               &:nth-child(even) {
                  max-width: 25%;

                  @include desktop {
                     max-width: 35%;
                  }

                  @include tablet {
                     max-width: 35%;
                  }
               }

               &:nth-child(odd) {
                  max-width: 75%;

                  @include desktop {
                     max-width: 65%;
                  }

                  @include tablet {
                     max-width: 65%;
                  }
               }
            }
         }
      }
   }

   &.hide {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      transition: .4s;
      transition-delay: 0s;
      width: 100%;
      transform: translateY(15px);
      top: 0px;
      left: 0px;
   }

   &.show {
      visibility: visible;
      opacity: 1;
      position: relative;
      transform: translateY(0px);
      transition: .4s;
      transition-delay: .2s;
      z-index: 4;
   }
}