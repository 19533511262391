@import '../../../scss/media';

.typical_text {
   color: #121212;
   font-family: "Onest", sans-serif;
   font-size: 20px;
   font-size: clamp(1.125rem, 0.5rem + 0.625vw, 1.25rem);
   // 20px > 18px
   font-weight: 400;
   line-height: 28px;
   line-height: clamp(1.625rem, 1rem + 0.625vw, 1.75rem);
   // 28px > 26px
   letter-spacing: 0.4px;

   @include desktop {
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.36px;
   }

   @include tablet {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.32px;
   }

   &.small {
      font-size: 18px;
      font-size: clamp(1rem, 0.375rem + 0.625vw, 1.125rem);
      // 18px > 16px
      font-weight: 400;
      line-height: 26px;
      line-height: clamp(1.375rem, 0.125rem + 1.25vw, 1.625rem);
      // 26px > 22px
      letter-spacing: 0.36px;

      @include desktop {
         font-size: 16px;
         line-height: 22px;
         letter-spacing: unset;
      }

      @include tablet {
         font-size: 14px;
         line-height: normal;
      }
   }

   &.gray {
      color: #C9C9C9;
   }

   &.white {
      color: #FFF;
   }

   &.ibm {
      font-family: "IBM Plex Mono", sans-serif;
      letter-spacing: 0px;
   }

   &.center {
      text-align: center;
   }

   a {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      letter-spacing: inherit;
   }
}