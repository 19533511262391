@import '../../../scss/media';

// .ReactModal__Content {
//   max-width: 1114px;
//   margin: auto;

//   padding: 0 !important;
//   height: 100%;

//   box-sizing: border-box;
//   height: auto;
//   background: transparent !important;
//   border: 0px !important;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   @include mobile {
//     inset:0px!important;
//   }
// }

.remodal {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: clamp(56.25rem, -10.938rem + 67.188vw, 69.688rem);
  // 1115px > 900px
  background: #fff;
  border-radius: 10px;
  max-height: 100%;
  position: relative;
  padding: 50px;
  padding: clamp(2.5rem, -0.625rem + 3.125vw, 3.125rem);
  // 50px> 40px
  overflow: hidden;
  padding-right: 15px;

  @include desktop {
    max-width: 900px;
    padding: 40px;
    padding-right: 15px;
  }

  @include tablet {
    padding: 30px;
    padding-right: 10px;
  }

  @include mobile {}

  &_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-right: 35px;
    overflow-y: auto;

    @include desktop {
      padding-right: 25px;
    }

    @include tablet {
      padding-right: 10px;
    }
  }

  .close-modal {
    position: absolute;
    background: #F5F5F7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    top: 30px;
    top: clamp(1.563rem, 0rem + 1.563vw, 1.875rem);
    // 30px > 25px
    right: 30px;
    right: clamp(1.563rem, 0rem + 1.563vw, 1.875rem);
    // 30px > 25px

    width: 38px;
    width: clamp(1.875rem, -0.625rem + 2.5vw, 2.375rem);
    // 38px > 30px
    height: 38px;
    height: clamp(1.875rem, -0.625rem + 2.5vw, 2.375rem);
    // 38px > 30px

    cursor: pointer;

    transition: .4s;

    @include hover {
      opacity: .7;
    }

    @include desktop {
      top: 25px;
      right: 25px;
      width: 30px;
      height: 30px;
    }

    @include tablet {
      top: 20px;
    }
  }

  .icon {
    margin-bottom: 30px;
    margin-bottom: clamp(1.25rem, -1.875rem + 3.125vw, 1.875rem);
    // 30px > 20px
    display: flex;
    justify-content: center;
    width: 50px;
    width: clamp(2.5rem, -0.625rem + 3.125vw, 3.125rem);
    // 50px > 40px
    height: 50px;
    height: clamp(2.5rem, -0.625rem + 3.125vw, 3.125rem);
    // 50px > 40px

    @include desktop {
      width: 40px;
      height: 40px;
      margin-bottom: 20px;
    }

    @include mobile {
      margin-bottom: 20px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .title_block {
    width: 100%;
    max-width: 540px;
    max-width: clamp(28.125rem, 0rem + 28.125vw, 33.75rem);
    // 540px > 450px
    color: #121212;
    text-align: center;
    font-size: 26px;
    font-size: clamp(1.25rem, -0.625rem + 1.875vw, 1.625rem);
    // 26px > 20px
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    line-height: clamp(1.625rem, -0.875rem + 2.5vw, 2.125rem);
    // 34px > 26px
    letter-spacing: 0.52px;
    margin-bottom: 10px;

    @include desktop {
      font-size: 20px;
      line-height: normal;
      letter-spacing: 0.4px;
      max-width: 450px;
      margin-bottom: 10px;
    }

    @include tablet {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.36px;
    }

    @include mobile {
      color: #121212;
      font-size: 28px;
      line-height: normal;
      letter-spacing: -0.56px;
      margin-bottom: 16px;
    }
  }

  .loader {
    margin: 40px 0;
    margin: clamp(1.875rem, -1.25rem + 3.125vw, 2.5rem) 0;
    // 40px > 30px

    @include desktop {
      margin: 30px 0;
    }

    @include tablet {
      margin: 20px 0;
    }

    svg {
      @include desktop {
        width: 50px;
        height: 50px;
      }
    }
  }

  .descr {
    width: 100%;
    max-width: 540px;
    max-width: clamp(28.125rem, 0rem + 28.125vw, 33.75rem);
    // 540px > 450px
    color: #121212;
    text-align: center;

    font-size: 16px;
    font-size: clamp(0.875rem, 0.25rem + 0.625vw, 1rem);
    // 16px > 14px
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    line-height: clamp(1.375rem, 0.75rem + 0.625vw, 1.5rem);
    // 24px > 22px
    letter-spacing: 0.32px;
    padding-left: 20px;
    padding-right: 20px;

    @include desktop {
      font-size: 14px;
      line-height: 22px;
      max-width: 450px;
    }

    @include mobile {
      font-size: 18px;
      line-height: normal;
      letter-spacing: 0.36px;
    }
  }

  form {
    width: 100%;
    max-width: 540px;
    max-width: clamp(28.125rem, 0rem + 28.125vw, 33.75rem);
    // 540px > 450px
    margin-top: 30px;
    margin-top: clamp(1.25rem, -1.875rem + 3.125vw, 1.875rem);
    // 30px > 20px

    @include desktop {
      max-width: 450px;
      margin-top: 20px;
    }

    @include tablet {
      margin-top: 30px;
    }


    .btn_sub {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      margin-top: clamp(1.25rem, -1.875rem + 3.125vw, 1.875rem);
      // 30px > 20px

      @include desktop {
        margin-top: 20px;
      }

      @include mobile {
        margin-top: 30px;
      }

      &-btn {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 7px 16px;
        border-radius: 50px;
        background: #006AFE;
        margin-left: auto;
        margin-right: auto;

        color: #FFF;
        text-align: center;
        font-family: "IBM Plex Mono";
        font-size: 18px;

        line-height: 24px;

        letter-spacing: 0.36px;
        text-transform: uppercase;
        cursor: pointer;

        transition: .4s;

        &:disabled {
          opacity: .5;
          user-select: none;
          pointer-events: none;
        }

        @include hover {
          opacity: .7;
        }
      }
    }

    .item {
      margin-bottom: 10px;
      height: 58px;

      input {
        padding: 0 20px;
        height: 100%;
        border-radius: 12px;
        border: 1px solid #E8E8EB;
        background: #FFF;
        color: #121212;
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.32px;
      }
    }

    .modal_check {
      margin-top: 18px;

      @include tablet {
        margin-top: 16px;
      }

      input {
        display: none;

        &:checked+label:before {
          border-color: #006AFE;
          background-color: #006AFE;
        }
      }

      label {
        color: #121212;

        font-size: 16px;
        font-size: clamp(0.875rem, 0.25rem + 0.625vw, 1rem);
        // 16px > 14px
        font-style: normal;
        font-weight: 400;
        line-height: clamp(1.375rem, 0.75rem + 0.625vw, 1.5rem);
        // 24px > 22px
        letter-spacing: 0.32px;
        display: inline-block;
        gap: 10px;
        cursor: pointer;

        a {
          font-size: inherit;
          font-style: inherit;
          font-weight: inherit;
          color: #006AFE;
          line-height: inherit;
          letter-spacing: inherit;

          transition: .4s;

          @include hover {
            opacity: .7;
          }
        }

        @include desktop {
          font-size: 14px;
          line-height: 22px;
        }

        @include mobile {
          font-size: 16px;
        }

        &:before {
          content: '';
          display: inline-block;
          width: 14px;
          min-width: 14px;
          height: 14px;
          background: transparent;
          transition: .4s;
          border: 1px solid var(--black, #121212);
          border-radius: 4px;
          background-size: 80% 80%;
          background-repeat: no-repeat;
          margin-right: 10px;
          background-position: center center;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
        }
      }
    }
  }
}

// .ReactModal__Overlay {
//   background: rgba(0, 0, 0, 0.10) !important;
// }