:root {
   --translateX: 0px;
}

@import '../../../scss/media';

.first_block_wrap-scheme {
   position: relative;
   margin-top: 76px;
   height: 280px;

   @include desktop {
      height: 250px;
      margin-top: 50px;
   }

   @include tablet {
      margin-top: 60px;
      height: 200px;
   }

   @include mobile {
      height: 150px;
      margin-top: 50px;
   }

   svg {
      --translateX: 0px;
      animation-name: transformXsvgNoActive;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
      transform: translateX(var(--translateX));
      width: auto;
      height: 100%;

      &.active {
         animation-name: transformXsvg;
         animation-duration: 9.6s;
         animation-fill-mode: forwards;
         animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
         transform: translateX(var(--translateX));

         @include mobile {
            animation-timing-function: ease-in-out;
            animation-duration: 10.5s;
         }
      }

      .all_group {

         &.active {

            .first_group,
            .logo_group,
            .mobile_group,
            .telephone_group,
            .big_group-item,
            .last_group,
            .middle_group-item,
            .line_group-line {
               animation-name: showScaleElement;
               animation-duration: 1.2s;
               animation-fill-mode: forwards;
               animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
            }
         }

         .first_group,
         .logo_group,
         .mobile_group,
         .telephone_group,
         .big_group-item,
         .last_group,
         .middle_group-item,
         .line_group-line {
            animation-name: showScaleElementNoActive;
            animation-duration: .1s;
            animation-fill-mode: forwards;
            animation-timing-function: linear;
         }
      }

      .group_anim-item {
         display: block;
         transform-box: border-box;
         transform: scaleX(0);
         transform-origin: 50% 50%;
      }

      .first_group,
      .logo_group,
      .mobile_group,
      .telephone_group,
      .big_group-item,
      .last_group,
      .middle_group-item,
      .line_group-line {
         animation-name: showScaleElement;
         animation-duration: 1.2s;
         animation-fill-mode: forwards;
         animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
      }


      .first_group {
         animation-delay: .2s;
      }

      .logo_group {
         animation-delay: 1.2s;
      }

      .mobile_group {
         animation-delay: 2.4s;

         @include mobile {
            animation-delay: 2.8s;
         }
      }

      .telephone_group {
         animation-delay: 3.6s;

         @include mobile {
            anidation-delay: 3.8s;
         }
      }

      .big_group {
         &-item {
            display: block;
            transform-box: border-box;
            transform: scaleX(0);
            transform-origin: 50% 50%;

            &-1 {
               animation-delay: 4.9s;
            }

            &-2 {
               animation-delay: 5s;
            }

            &-3 {
               animation-delay: 5.1s;
            }
         }
      }

      .middle_group {
         &-item {
            display: block;
            transform-box: border-box;
            transform: scaleX(0);
            transform-origin: 50% 50%;

            &-1 {
               animation-delay: 6.3s;

               @include mobile {
                  animation-delay: 6.7s;
               }
            }

            &-2 {
               animation-delay: 6.3s;

               @include mobile {
                  animation-delay: 6.7s;
               }
            }
         }
      }

      .last_group {
         animation-delay: 7.6s;

         @include mobile {
            animation-delay: 8.7s;
         }
      }

      .line_group {

         &-line {
            display: block;
            transform-box: border-box;
            transform: scaleX(0);
            transform-origin: 0% 50%;
         }

         .line-1 {
            animation-delay: 1.2s;
         }

         .line-2 {
            animation-delay: 2.4s;
         }

         .line-3 {
            animation-delay: 3.6s;
         }

         .line-4-1 {
            animation-delay: 4.6s;
         }

         .line-4-2 {
            animation-delay: 4.7s;
         }

         .line-4-3 {
            animation-delay: 4.8s;
         }

         .line-5-1 {
            animation-delay: 6.3s;

            @include mobile {
               animation-delay: 6.7s;
            }
         }

         .line-5-2 {
            animation-delay: 6.3s;

            @include mobile {
               animation-delay: 6.7s;
            }
         }

         .line-6-1 {
            animation-delay: 7.5s;

            @include mobile {
               animation-delay: 8.6s;
            }
         }
      }

      .groups_voice {
         &-item {
            transform-origin: 0 15%;

            &-2 {
               transform-origin: 0 50%;
            }

            &-3 {
               transform-origin: 0 85%;
            }
         }

         &-1 {
            animation: voice1 1s forwards ease-in-out infinite;
         }

         &-2 {
            animation: voice2 1s forwards ease-in-out infinite;
         }

         &-3 {
            animation: voice3 1s forwards ease-in-out infinite;
         }

         &-4 {
            animation: voice4 1s forwards ease-in-out infinite;
         }

         &-5 {
            animation: voice5 1s forwards ease-in-out infinite;
         }
      }

      @keyframes voice1 {
         0% {
            transform: scale(1);

         }

         50% {
            transform: scaleY(.8);
         }

         100% {
            transform: scale(1);
         }
      }

      @keyframes voice2 {
         0% {
            transform: scale(1);
         }

         50% {
            transform: scaleY(1.1);
         }

         100% {
            transform: scale(1);
         }
      }

      @keyframes voice3 {
         0% {
            transform: scale(1);
         }

         50% {
            transform: scaleY(.6);
         }

         100% {
            transform: scale(1);
         }
      }

      @keyframes voice4 {
         0% {
            transform: scale(1);
         }

         50% {
            transform: scaleY(.9);
         }

         100% {
            transform: scale(1);
         }
      }

      @keyframes voice5 {
         0% {
            transform: scale(1);
         }

         50% {
            transform: scaleY(1.3);
         }

         100% {
            transform: scale(1);
         }
      }
   }

   &::after {
      content: '';
      display: block;
      width: 220px;
      height: 100%;
      background: linear-gradient(90deg, #FFF 20%, rgba(255, 255, 255, 0.60) 50%, rgba(255, 255, 255, 0.00) 100%);
      position: absolute;
      left: -120px;
      top: 0px;
      z-index: 2;
      user-select: none;
      pointer-events: none;

      @include tablet {
         width: 93px;
         height: 220px;
         left: -13px;
         background: linear-gradient(90deg, #FFF 20%, rgba(255, 255, 255, 0.60) 63.91%, rgba(255, 255, 255, 0.00) 100%);
      }

      @include mobile {
         height: 160px;
      }

      @include mobile {
         width: 40px;
         background: linear-gradient(90deg, #FFF 20%, rgba(255, 255, 255, 0.60) 63.91%, rgba(255, 255, 255, 0.00) 100%);
      }
   }

   &::before {
      content: '';
      display: block;
      width: 220px;
      height: 100%;
      background: linear-gradient(-90deg, #FFF 20%, rgba(255, 255, 255, 0.60) 50%, rgba(255, 255, 255, 0.00) 100%);
      position: absolute;
      right: -120px;
      top: 0px;
      z-index: 2;
      user-select: none;
      pointer-events: none;

      @include tablet {
         display: none;
      }
   }

   // Анимация смещения !active
   @keyframes transformXsvgNoActive {
      from {
         opacity: 1;
      }

      to {
         opacity: 0;
      }
   }

   // Анимация смещения active
   @keyframes transformXsvg {
      from {
         transform: translateX(0px);
      }

      to {
         transform: translateX(var(--translateX));
      }
   }

   @include tablet {
      @keyframes transformXsvg {
         from {
            transform: translateX(40px);
         }

         to {
            transform: translateX(var(--translateX));
         }
      }
   }

   // Анимация появления !active
   @keyframes showScaleElementNoActive {
      0% {
         transform: scaleX(1);
         opacity: 1;
      }

      100% {
         transform: scaleX(0);
         opacity: 0;
      }
   }

   // Анимация появления active
   @keyframes showScaleElement {
      0% {
         transform: scaleX(0);
         opacity: 0;
      }

      100% {
         transform: scaleX(1);
         opacity: 1;
      }
   }
}