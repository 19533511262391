// Perfograma

@font-face {
   font-family: 'Perfograma';
   src: url('../fonts/perfograma/Perfograma.woff2') format('woff2');
   font-weight: normal;
   font-style: normal;
   font-display: swap;
}

// Onest

@font-face {
   font-family: 'Onest';
   src: url('../fonts/onset/Onest-Regular.woff2') format('woff2');
   font-weight: normal;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Onest';
   src: url('../fonts/onset/Onest-SemiBold.woff2') format('woff2');
   font-weight: 600;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Onest';
   src: url('../fonts/onset/Onest-Medium.woff2') format('woff2');
   font-weight: 500;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Onest';
   src: url('../fonts/onset/Onest-Black.woff2') format('woff2');
   font-weight: 900;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Onest';
   src: url('../fonts/onset/Onest-Light.woff2') format('woff2');
   font-weight: 300;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Onest';
   src: url('../fonts/onset/Onest-Bold.woff2') format('woff2');
   font-weight: bold;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Onest';
   src: url('../fonts/onset/Onest-ExtraLight.woff2') format('woff2');
   font-weight: 200;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Onest';
   src: url('../fonts/onset/Onest-Thin.woff2') format('woff2');
   font-weight: 100;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Onest';
   src: url('../fonts/onset/Onest-ExtraBold.woff2') format('woff2');
   font-weight: bold;
   font-style: normal;
   font-display: swap;
}

// IBM Plex Mono

@font-face {
   font-family: 'IBM Plex Mono';
   src: url('../fonts/ibm/IBMPlexMono-Medium.woff2') format('woff2');
   font-weight: 500;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'IBM Plex Mono';
   src: url('../fonts/ibm/IBMPlexMono-SemiBoldItalic.woff2') format('woff2');
   font-weight: 600;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'IBM Plex Mono';
   src: url('../fonts/ibm/IBMPlexMono-Bold.woff2') format('woff2');
   font-weight: bold;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'IBM Plex Mono';
   src: url('../fonts/ibm/IBMPlexMono-SemiBold.woff2') format('woff2');
   font-weight: 600;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'IBM Plex Mono';
   src: url('../fonts/ibm/IBMPlexMono-BoldItalic.woff2') format('woff2');
   font-weight: bold;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'IBM Plex Mono';
   src: url('../fonts/ibm/IBMPlexMono-MediumItalic.woff2') format('woff2');
   font-weight: 500;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'IBM Plex Mono';
   src: url('../fonts/ibm/IBMPlexMono-Regular.woff2') format('woff2');
   font-weight: normal;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'IBM Plex Mono';
   src: url('../fonts/ibm/IBMPlexMono-Thin.woff2') format('woff2');
   font-weight: 100;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'IBM Plex Mono';
   src: url('../fonts/ibm/IBMPlexMono-ThinItalic.woff2') format('woff2');
   font-weight: 100;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'IBM Plex Mono';
   src: url('../fonts/ibm/IBMPlexMono-ExtraLightItalic.woff2') format('woff2');
   font-weight: 200;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'IBM Plex Mono';
   src: url('../fonts/ibm/IBMPlexMono-LightItalic.woff2') format('woff2');
   font-weight: 300;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'IBM Plex Mono';
   src: url('../fonts/ibm/IBMPlexMono-Light.woff2') format('woff2');
   font-weight: 300;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'IBM Plex Mono';
   src: url('../fonts/ibm/IBMPlexMono-ExtraLight.woff2') format('woff2');
   font-weight: 200;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'IBM Plex Mono';
   src: url('../fonts/ibm/IBMPlexMono-Italic.woff2') format('woff2');
   font-weight: normal;
   font-style: italic;
   font-display: swap;
}