@use '../../../scss/media';

.tariffs_block {
   margin-top: 80px;
   margin-top: clamp(3.125rem, -2.5rem + 6.25vw, 5rem);
   // 80px > 50px

   @include media.desktop {
      margin-top: 50px;
   }

   @include media.tablet {
      margin-top: 30px;
   }

   .hidden {
      display: none;
   }

   &_item {
      display: flex;
      flex-direction: column;
      row-gap: 50px;

      @include media.tablet {
         row-gap: 30px;
      }

      &_head {
         display: flex;
         justify-content: space-between;
         align-items: flex-end;
         column-gap: 20px;
      }

      &-title {
         color: #121212;
         text-align: left;
         font-size: 42px;
         font-size: clamp(2.25rem, 0.375rem + 1.875vw, 2.625rem);
         font-weight: 400;
         line-height: 48px;
         line-height: clamp(2.625rem, 0.75rem + 1.875vw, 3rem);
         letter-spacing: -0.84px;
         max-width: 900px;
         max-width: media.fl(900, 780);
         // 900px > 700px

         @include media.desktop {
            color: #121212;
            font-size: 36px;
            line-height: normal;
            letter-spacing: -0.72px;
            max-width: 780px;
         }

         @include media.tablet {
            color: #121212;
            font-size: 28px;
            line-height: normal;
            letter-spacing: -0.56px;

            max-width: 100%;
            padding-right: 40px;
         }

         @include media.mobile {
            max-width: 100%;
            padding-right: 0px;
         }
      }

      &_list {
         row-gap: 20px;

         @include media.tablet {
            row-gap: 7px;
         }

         @include media.mobile {
            row-gap: 10px;
         }

         &_it {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 30px;
            row-gap: clamp(1.25rem, -0.625rem + 2.083vw, 1.875rem);
            // 30px > 20px
            border-radius: 10px;
            border: 1px solid #E8E8EB;
            background: #FFF;
            padding: 49px;
            padding: clamp(2.438rem, 0.563rem + 2.083vw, 3.063rem);
            // 49px > 39px
            position: relative;
            height: 100%;

            @include media.desktop {
               padding-top: 39px;
               row-gap: 20px;
            }

            @include media.tablet {
               padding: 20px;
            }

            @include media.mobile {
               row-gap: 16px;
            }

            &.priority {
               border: 1px solid #DFEBFF;
               background: #EDF4FF;

               .tariffs_block_item_list_it-btn {
                  color: #fff;
                  background: #006AFE;

                  svg {
                     path {
                        stroke: #fff;
                     }
                  }
               }
            }

            &-icon {
               display: flex;
               justify-content: center;
               align-items: center;
               overflow: hidden;
               width: 60px;
               height: 60px;
               aspect-ratio: 1/1;

               @include media.desktop {
                  width: 50px;
                  height: 50px;
               }

               @include media.tablet {
                  width: 40px;
                  height: 40px;
               }

               svg {
                  width: 100%;
                  height: 100%;
               }
            }

            &-title {
               color: #121212;
               font-size: 26px;
               font-weight: 400;
               line-height: 34px;
               letter-spacing: 0.52px;

               @include media.desktop {
                  font-size: 20px;
                  line-height: 28px;
                  letter-spacing: 0.4px;
               }

               @include media.tablet {
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: 0.32px;
               }
            }

            &-text {
               overflow: hidden;
               -webkit-box-orient: vertical;
               transition: .4s;


               >* {
                  &:last-child {
                     margin-bottom: 0px;
                  }
               }

               ul {
                  padding-left: 20px;
                  list-style-type: disc;

                  margin-bottom: 10px;

                  li {
                     margin-bottom: 10px;

                     @include media.tablet {
                        margin-bottom: 5px;
                     }

                     &:last-child {
                        margin: 0px;
                     }
                  }
               }

               p {
                  margin-bottom: 10px;
               }

               ol {
                  padding-left: 5px;
                  margin-bottom: 10px;
                  list-style: none;
                  /* Убираем стандартные маркеры */
                  counter-reset: custom-counter;
                  /* Сбрасываем счетчик */

                  li {
                     display: flex;
                     margin-bottom: 10px;
                     counter-increment: custom-counter;
                     /* Увеличиваем счетчик для каждого элемента */

                     &::before {
                        content: counter(custom-counter) ". ";
                        display: inline-flex;
                        color: inherit;
                        font-size: inherit;
                        font-weight: inherit;
                        line-height: inherit;
                        line-height: inherit;
                        letter-spacing: inherit;
                        margin-right: 5px;
                     }

                     @include media.tablet {
                        margin-bottom: 5px;
                     }

                     &:last-child {
                        margin: 0px;
                     }
                  }
               }
            }

            &-cost {
               display: inline-flex;
               justify-content: center;
               align-items: center;
               border-radius: 0px 0px 10px 10px;
               background: #006AFE;

               padding: 7px 16px;
               padding: clamp(0.375rem, 0.188rem + 0.208vw, 0.438rem) clamp(0.813rem, 0.25rem + 0.625vw, 1rem);
               // 7px > 6px
               // 16px > 13px

               color: #FFF;
               text-align: center;
               font-size: 20px;
               font-size: clamp(1rem, 0.25rem + 0.833vw, 1.25rem);
               // 20px > 16px
               font-weight: 400;
               line-height: 28px;
               line-height: clamp(1.5rem, 0.75rem + 0.833vw, 1.75rem);
               // 28px > 24px
               letter-spacing: 0.4px;

               position: absolute;
               top: 0px;
               right: 50px;
               right: clamp(2.5rem, 0.625rem + 2.083vw, 3.125rem);
               // 50px > 40px
               z-index: 2;

               @include media.desktop {
                  right: 40px;
                  font-size: 16px;
                  line-height: 24px;
                  padding: 6px 13px;
               }

               @include media.tablet {
                  padding: 5px 12px;
                  right: 20px;

                  font-size: 12px;
                  line-height: 18px;
                  letter-spacing: 0.28px;
               }
            }

            &-btn {
               display: inline-flex;
               justify-content: center;
               align-items: center;
               padding: 7px 16px;
               padding: clamp(0.375rem, 0.063rem + 0.313vw, 0.438rem) clamp(0.813rem, -0.125rem + 0.938vw, 1rem);
               column-gap: 10px;
               border-radius: 50px;
               background: #F5F5F7;

               color: #121212;
               text-align: center;
               font-family: "IBM Plex Mono";
               font-size: 18px;
               font-size: clamp(0.875rem, -0.375rem + 1.25vw, 1.125rem);
               font-weight: 400;
               line-height: 24px;
               line-height: clamp(1.25rem, 0rem + 1.25vw, 1.5rem);
               letter-spacing: 0.36px;
               text-transform: uppercase;

               cursor: pointer;
               transition: .4s;

               @include media.desktop {
                  padding: 6px 13px;
                  font-size: 14px;
                  line-height: normal;
                  letter-spacing: 0.24px;
               }

               @include media.tablet {
                  font-size: 12px;
                  padding: 5px 12px;
               }

               @include media.hover {
                  opacity: .7;
               }

               .icon {
                  display: flex;
                  width: 14px;
                  height: 14px;

                  svg {
                     width: 100%;
                     height: 100%;

                     .hide {
                        opacity: 1;
                        transition: .4s;
                     }
                  }
               }

               &.active {
                  .icon {

                     .hide {
                        opacity: 0;
                     }
                  }
               }
            }
         }
      }

      &-text_bot {
         max-width: 1100px;
         max-width: clamp(62.5rem, 31.25rem + 31.25vw, 68.75rem);
         // 1100px > 1000px

         @include media.desktop {
            max-width: 1000px;
         }

         @include media.tablet {
            max-width: 768px;
         }
      }
   }

   .swiper_tariffs {
      width: 100%;
      max-width: 100%;
      margin-left: 0px;
      margin-right: 0px;

      .tariffs_block_item_list_it-text {
         display: block;
      }

      &_controls {
         display: flex;
         column-gap: 20px;
         position: relative;
         top: 0;
         right: 0;

         @include media.tablet {
            column-gap: 10px;
         }

         .swiper-button-lock {
            display: none !important;
         }

         .swiper-pagination-lock {
            display: none !important;
         }

         &-prev {
            display: flex;
            position: unset;
            padding: 0px;
            margin: 0px;

            transition: .4s;

            &::after {
               display: none;
            }

            &.swiper-button-disabled {
               opacity: 1;

               rect {
                  stroke: #C9C9C9;
               }

               path {
                  stroke: #C9C9C9;
               }
            }
         }

         &-next {
            display: flex;
            position: unset;
            padding: 0px;
            margin: 0px;

            transition: .4s;

            &::after {
               display: none;
            }

            &.swiper-button-disabled {
               opacity: 1;

               rect {
                  stroke: #C9C9C9;
               }

               path {
                  stroke: #C9C9C9;
               }
            }
         }

         .swiper-pagination {
            display: flex;
            align-items: center;
            position: unset;
            padding: 0px;
            margin: 0px;
            width: auto;
            height: auto;
            border-radius: 12px;
            border: 1px solid #C9C9C9;
            background: #FFF;
            padding: 0 19px;
            column-gap: 10px;

            @include media.tablet {
               border-radius: 4px;
            }

            &-bullet {
               display: flex;
               width: 10px;
               height: 10px;
               aspect-ratio: 1/1;
               margin: 0px;
               padding: 0px;
               background: #E8E8EB;
               opacity: 1;
               margin: 0 !important;

               @include media.tablet {
                  width: 6px;
                  height: 6px;
               }

               transition: .4s;

               &-active {
                  background: #006AFE;
               }
            }
         }
      }
   }
}