@use "../../scss/media";

.product_page {
   margin-top: 60px;

   @include media.desktop {
      margin-top: 80px;
   }

   @include media.tablet {
      margin-top: 30px;
   }

   .product_head-first {
      .autoWidth {
         width: auto;
      }

      .section_head-title {
         color: #121212;
         text-align: center;
         font-size: 56px;
         font-size: media.fl(56, 50);
         font-weight: 400;
         line-height: 64px;
         line-height: media.fl(64, 58);
         letter-spacing: -1.12px;

         @include media.desktop {
            font-size: 50px;
            line-height: 58px;
            letter-spacing: -1px;
         }

         @include media.tablet {
            font-size: 28px;
            line-height: normal;
            letter-spacing: -0.56px;
         }
      }

      .first_block_wrap_head-btn {
         @include media.tablet {
            margin-top: 14px;
         }
      }

      .product_head-first_sub {
         display: flex;
         flex-direction: column;
         row-gap: 16px;
         margin-top: -16px;
         margin-top: calc(0px - media.fl(16, -4));

         @include media.desktop {
            margin-top: 4px;
            max-width: 800px;
         }

         @include media.tablet {
            margin-top: 0px;
            max-width: 600px;
         }

         @include media.mobile {
            row-gap: 24px;
         }

         &-title {
            color: #A8A8A8;
            text-align: center;
            font-family: Onest;
            font-size: 34px;
            font-size: media.fl(34, 28);
            font-style: normal;
            font-weight: 400;
            line-height: 44px;
            line-height: media.fl(44, 38);
            letter-spacing: -0.68px;

            @include media.desktop {
               font-size: 28px;
               line-height: normal;
               letter-spacing: -0.56px;
            }

            @include media.tablet {
               font-size: 20px;
               line-height: 28px;
               letter-spacing: 0.4px;
            }
         }

         &-text {
            color: #A8A8A8;
            text-align: center;
            font-family: Onest;
            font-size: 20px;
            font-size: media.fl(20, 18);
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            line-height: media.fl(28, 26);
            letter-spacing: 0.4px;

            @include media.desktop {
               font-size: 18px;
               line-height: 26px;
               letter-spacing: 0.36px;
            }

            @include media.tablet {
               font-size: 16px;
               line-height: 24px;
               letter-spacing: 0.32px;
            }
         }
      }
   }

   .line_top-bot {
      user-select: none;
      pointer-events: none;

      &::before {
         content: '';
         display: block;
         width: calc(100% + 100px);
         width: calc(100% + media.fl(100, 60));
         height: 2px;
         background: #E8E8EB;
         position: absolute;
         bottom: 0;
         left: 50%;
         transform: translateX(-50%);

         @include media.desktop {
            width: calc(100% + 60px);
         }

         @include media.tablet {
            display: none;
         }
      }

      &::after {
         content: '';
         display: block;
         width: calc(100% + 100px);
         width: calc(100% + media.fl(100, 60));
         height: 2px;
         background: #E8E8EB;
         position: absolute;
         top: 0;
         left: 50%;
         transform: translateX(-50%);

         @include media.desktop {
            width: calc(100% + 60px);
         }

         @include media.tablet {
            display: none;
         }
      }
   }

   .line_right-left {
      user-select: none;
      pointer-events: none;

      &::before {
         content: '';
         display: block;
         width: 2px;
         height: calc(100% + 100px);
         height: calc(100% + media.fl(100, 60));
         background: #E8E8EB;
         position: absolute;
         top: 50%;
         transform: translateY(-50%);
         left: 0px;

         @include media.desktop {
            height: calc(100% + 60px);
         }

         @include media.tablet {
            display: none;
         }
      }

      &::after {
         content: '';
         display: block;
         width: 2px;
         height: calc(100% + media.fl(100, 60));
         background: #E8E8EB;
         position: absolute;
         top: 50%;
         transform: translateY(-50%);
         right: 0px;

         @include media.desktop {
            height: calc(100% + 60px);
         }

         @include media.tablet {
            display: none;
         }
      }
   }
}