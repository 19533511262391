@import '../../scss/media';

.partner_page {
  margin-top: 60px;
  margin-top: clamp(3.125rem, 0rem + 3.125vw, 3.75rem);
  // 60px > 50px

  @include desktop {
    margin-top: 50px;
  }

  @include tablet {
    margin-top: 30px;
  }

  &_top {
    width: 100%;
    max-width: 800px;
    max-width: clamp(43.125rem, 8.75rem + 34.375vw, 50rem);
    // 800px > 690px
    margin-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;

    @include desktop {
      max-width: 690px;
    }

    @include tablet {
      max-width: 400px;
    }

    .section_head-icon {
      animation: rotateIcon 3s forwards infinite;
    }
  }

  &_benefits {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
    row-gap: clamp(1.25rem, -1.875rem + 3.125vw, 1.875rem);
    // 30px > 20px

    margin-top: 120px;
    margin-top: clamp(5rem, -7.5rem + 12.5vw, 7.5rem);
    // 120px > 80px

    @include desktop {
      margin-top: 80px;
      row-gap: 20px;
    }

    @include tablet {
      margin-top: 60px;
      row-gap: 10px;
    }

    &-title {
      text-align: center;

      @include tablet {
        max-width: 240px;
      }
    }
  }

  &_driven {
    margin-top: 180px;
    margin-top: clamp(7.5rem, -11.25rem + 18.75vw, 11.25rem);
    // 180px > 120px

    @include desktop {
      margin-top: 120px;
    }

    @include tablet {
      margin-top: 60px;
    }

    .section_title {
      @include tablet {
        max-width: 350px;
      }
    }

    .data_driven_sw_item {
      height: auto;
      max-height: unset;
    }

    .data_drive_wrap_swipers {
      .data_driven_sw_mobile {
        row-gap: 20px;

        @include tablet {
          row-gap: 7px;
        }

        @include mobile {
          row-gap: 10px;
        }

        .data_driven_sw_mobile_item {
          @include tablet {
            width: 100%;
            max-width: 100%;
            height: 100%;
          }
        }
      }

    }
  }

  &_partnership {
    margin-top: 120px;
    margin-top: clamp(6.25rem, 0rem + 8.333vw, 7.5rem);
    // 120px > 100px

    @include desktop {
      margin-top: 100px;
    }

    @include tablet {
      margin-top: 30px;
    }

    &_wrap {
      display: flex;
      flex-direction: column;
      row-gap: 50px;
      row-gap: clamp(2.5rem, -0.625rem + 4.167vw, 3.125rem);
      // 50px > 40px

      @include desktop {
        row-gap: 40px;
      }

      @include tablet {
        row-gap: 24px;
      }

      &_head {
        margin-bottom: 0px;

        .btn {
          margin-top: 10px;

          @include tablet {
            margin-top: 0px;
          }
        }
      }

      .benefits {
        &_item {

          align-items: center;

          @include desktop {
            flex-direction: row;
          }

          @include tablet {
            padding: 15px 13px 15px 15px;
            column-gap: 20px;
          }

          @include mobile {
            height: auto;
          }

          &_content {

            @include desktop {
              max-width: calc(100% - 30px - 50px);
            }

            @include tablet {
              max-width: calc(100% - 20px - 40px);
            }

            .typical_text {

              @include tablet {
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.28px;
              }

              @include mobile {
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0.32px;
              }
            }

            .custom_link {
              display: inline-flex;
              align-items: center;
              column-gap: 10px;
              column-gap: clamp(0.313rem, -1.25rem + 1.563vw, 0.625rem);
              // 10px > 5px
              margin-top: 8px;
              margin-top: clamp(0.25rem, -1rem + 1.25vw, 0.5rem);
              // 8px > 4px

              color: #C9C9C9;
              font-size: 18px;
              font-size: clamp(1rem, 0.375rem + 0.625vw, 1.125rem);
              // 18px > 16px
              font-weight: 400;
              line-height: 24px;
              text-transform: lowercase;

              @include desktop {
                margin-top: 4px;
                column-gap: 5px;

                font-size: 16px;
                font-size: clamp(0.875rem, 0.25rem + 0.833vw, 1rem);
                // 16px > 14px
                line-height: 24px;
                line-height: clamp(1.25rem, 0rem + 1.667vw, 1.5rem);
                // 24px > 20px
                letter-spacing: 0.32px;
              }

              @include tablet {
                margin-top: 2px;
                column-gap: 8px;

                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.28px;
              }

              @include hover {
                span {
                  &::after {
                    transform-origin: 100% 0%;
                    transform: scaleX(0);
                  }
                }
              }

              span {
                position: relative;

                &::after {
                  content: '';
                  display: block;
                  width: 100%;
                  height: 1px;
                  background: currentColor;
                  position: absolute;
                  bottom: -1px;
                  left: 0;
                  transform: scaleX(1);
                  transform-origin: 0 100%;
                  transition: transform .4s;

                  @include desktop {
                    bottom: -2px;
                  }
                }


              }

              .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50px;
                border: 1px solid #C9C9C9;
                padding: 2px 9px 3px 9px;

                color: inherit;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                text-transform: inherit;

                @include desktop {
                  padding: 0px 7px;
                }
              }
            }
          }
        }
      }
    }
  }
}