@import '../../../scss/media';

.products {
   scroll-margin-top: 120px;
   scroll-margin-top: 120px;
   scroll-margin-top: clamp(6.25rem, 0rem + 6.25vw, 7.5rem);

   @include desktop {
      scroll-margin-top: 100px;
   }

   @include tablet {
      scroll-margin-top: 30px;
   }

   @include mobile {
      scroll-margin-top: 80px;

      &.open {
         scroll-margin-top: 30px;
      }
   }

   &_head {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      row-gap: 10px;
      column-gap: 10px;

      &-title {}

      &-btn {}
   }

   &_wrap {
      margin-top: 50px;
      margin-top: clamp(2.5rem, -0.625rem + 3.125vw, 3.125rem);
      // 50px > 40px

      @include desktop {
         margin-top: 40px;
         overflow: visible;
      }

      @include tablet {
         margin-top: 24px;
      }

      .swiper-slide {
         @include desktop {
            max-width: 350px;
            height: auto;
         }

         @include tablet {
            max-width: 305px;
         }

         @include mobile {
            max-width: 320px;
         }
      }

      &_item {
         display: flex;
         flex-direction: column;
         border-radius: 10px;
         background: #FFF;
         overflow: hidden;
         height: 100%;
         background: rgba(255, 161, 111, 1);
         position: relative;

         &-image {
            position: relative;
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 100%;
            height: auto;
            overflow: hidden;
            aspect-ratio: 81/58;

            video {
               width: calc(100% + 2px) !important;
               height: 100%;
               object-fit: cover;
            }


            @include desktop {
               aspect-ratio: 305/269;
            }

            img {
               width: 100%;
               height: 100%;
               object-fit: cover;
            }
         }

         &_content {
            position: relative;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            row-gap: 20px;
            row-gap: clamp(0.625rem, -2.5rem + 3.125vw, 1.25rem);
            // 20px > 10px
            padding: 30px 39px 40px 39px;
            padding-top: clamp(1.25rem, -1.875rem + 3.125vw, 1.875rem);
            // 30px > 20px
            padding-right: clamp(1.25rem, -4.688rem + 5.938vw, 2.438rem);
            // 39px > 20px
            padding-bottom: clamp(1.25rem, -5rem + 6.25vw, 2.5rem);
            // 40px > 20px
            padding-left: clamp(1.25rem, -4.688rem + 5.938vw, 2.438rem);
            // 39px > 20px
            //background: radial-gradient(50% 49.96% at 50% 50.04%, #F2651A 15.5%, #FFA16F 100%);
            flex: 1 1 auto;

            @include desktop {
               padding: 20px;
               row-gap: 10px;
            }
         }

         &-title {
            position: relative;
            z-index: 2;
            color: #FFF;
            font-size: 26px;
            font-size: clamp(1.25rem, -0.625rem + 1.875vw, 1.625rem);
            // 26px > 20px
            font-weight: 400;
            line-height: 34px;
            line-height: clamp(1.75rem, -0.125rem + 1.875vw, 2.125rem);
            // 34px > 28px
            letter-spacing: 0.52px;

            @include desktop {
               font-size: 20px;
               line-height: 28px;
               letter-spacing: 0.4px;
            }

            @include tablet {
               font-size: 18px;
               line-height: normal;
               letter-spacing: 0.36px;
            }
         }

         &-text {
            position: relative;
            z-index: 2;
         }

         &-btn {
            position: relative;
            z-index: 2;
            position: relative;
            z-index: 2;
            margin-top: 10px;
            flex: 1 1 auto;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            @include desktop {
               margin-top: 20px;
            }

            @include mobile {
               margin-top: 10px;
            }

            &-btn {
               display: inline-flex;
               justify-content: center;
               align-items: center;
               column-gap: 10px;
               padding: 7px 16px;
               padding: clamp(0.313rem, -0.313rem + 0.625vw, 0.438rem) clamp(0.875rem, 0.25rem + 0.625vw, 1rem);
               // 7px > 5px
               // 16px > 14px
               border-radius: 50px;
               background: #FFF;

               color: #121212;
               text-align: center;
               font-family: "IBM Plex Mono";
               font-size: 18px;
               font-size: clamp(0.875rem, -0.375rem + 1.25vw, 1.125rem);
               // 18px > 14px
               font-weight: 400;
               line-height: 24px;
               line-height: clamp(1.25rem, 0rem + 1.25vw, 1.5rem);
               // 24px > 20px
               letter-spacing: 0.36px;
               text-transform: uppercase;
               cursor: pointer;

               transition: .4s;

               @include hover {
                  opacity: .7;
               }

               @include desktop {
                  padding: 5px 14px;

                  font-size: 14px;
                  line-height: normal;
                  letter-spacing: 0.28px;
               }

               @include tablet {
                  font-size: 12px;
                  letter-spacing: 0.24px;
               }

               .icon {
                  display: flex;
                  width: 14px;
                  width: clamp(0.625rem, -0.625rem + 1.25vw, 0.875rem);
                  // 14px > 10px
                  height: 14px;
                  height: clamp(0.625rem, -0.625rem + 1.25vw, 0.875rem);
                  // 14px > 10px

                  @include desktop {
                     width: 10px;
                     height: 10px;
                  }

                  svg {
                     width: 100%;
                     height: 100%;
                  }
               }
            }
         }

         &-circle {
            display: block;
            background: rgba(242, 101, 26, 1);
            width: calc(100% - 120px);
            height: calc(100% - 120px);
            position: absolute;
            top: 50%;
            left: 50%;
            translate: -50% -50%;
            border-radius: 50%;
            user-select: none;
            pointer-events: none;
            will-change: transform;
            filter: blur(70px);
            opacity: 0;
            transition: opacity .6s;
         }

         &-circle_static {
            display: block;
            display: block;
            background: rgba(242, 101, 26, 1);
            width: calc(100% - 120px);
            height: calc(100% - 120px);
            position: absolute;
            top: 50%;
            left: 50%;
            translate: -50% -50%;
            border-radius: 50%;
            user-select: none;
            pointer-events: none;
            will-change: transform;
            filter: blur(70px);
         }
      }
   }
}