@use '../../../scss/media';

.error_page {
   margin: auto 0;
   padding: 170px 0 10px 0;
   padding: media.fl(170, 120) 0 10px 0;

   @include media.desktop {
      row-gap: 20px;
      padding: 120px 0 10px 0;
   }

   @include media.tablet {
      row-gap: 24px;
      padding: 100px 0 70px 0;
   }

   &_wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 30px;
      row-gap: media.fl(30, 20);
      max-width: 546px;
      width: 100%;
      margin: 0 auto;

      @include media.desktop {
         row-gap: 20px;
      }

      @include media.tablet {
         row-gap: 24px;
      }

      &-title {
         color: #006AFE;
         text-align: center;
         font-size: 56px;
         font-size: media.fl(56, 50);
         font-style: normal;
         font-weight: 400;
         line-height: 64px;
         line-height: media.fl(64, 58);
         /* 114.286% */
         letter-spacing: -1.12px;

         @include media.desktop {
            font-size: 50px;
            line-height: 58px;
            letter-spacing: -1px;
         }

         @include media.tablet {
            font-size: 28px;
            line-height: normal;
            letter-spacing: -0.56px;
         }
      }

      &-text {
         text-align: center;
      }

      &-link {}
   }

}