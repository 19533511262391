@import '../../../scss/media';

.section_head {
   display: flex;
   flex-direction: column;
   align-items: center;
   row-gap: 30px;
   row-gap: clamp(1rem, -3.375rem + 4.375vw, 1.875rem);
   // 30px / 16px
   max-width: 900px;
   margin-left: auto;
   margin-right: auto;

   margin-bottom: 60px;
   margin-bottom: clamp(2.5rem, -3.75rem + 6.25vw, 3.75rem);
   // 60px > 40px

   @include desktop {
      row-gap: 16px;
      max-width: 800px;

      margin-bottom: 40px;
   }

   @include tablet {
      margin-bottom: 24px;
      max-width: 750px;
   }

   >* {
      &:first-child {
         margin-top: 0px;
      }

      &:last-child {
         margin-bottom: 0px;
      }
   }

   &-icon {
      display: flex;
      margin-bottom: 10px;
      margin-bottom: clamp(0.5rem, -0.125rem + 0.625vw, 0.625rem);
      // 10px > 8px
      width: 60px;
      width: clamp(3.125rem, 0rem + 3.125vw, 3.75rem);
      // 60px > 50px
      height: 60px;
      height: clamp(3.125rem, 0rem + 3.125vw, 3.75rem);
      // 60px > 50px

      @include desktop {
         width: 50px;
         height: 50px;

         margin-bottom: 8px;
      }

      @include tablet {
         width: 40px;
         height: 40px;

         margin-bottom: 0px;
      }

      svg {
         width: 100%;
         height: 100%;
      }
   }

   &-title {
      color: #121212;
      text-align: center;
      font-size: 42px;
      font-size: clamp(2.25rem, 0.375rem + 1.875vw, 2.625rem);
      // 42px > 36px
      font-weight: 400;
      line-height: 48px;
      line-height: clamp(2.625rem, 0.75rem + 1.875vw, 3rem);
      // 48px > 42px
      letter-spacing: -0.84px;

      @include desktop {
         font-size: 36px;
         font-size: clamp(1.75rem, -0.75rem + 3.333vw, 2.25rem);
         // 36px > 28px
         line-height: normal;
         letter-spacing: -0.72px;
      }

      @include tablet {
         font-size: 28px;
         line-height: normal;
         letter-spacing: -0.56px;
      }

      &.white {
         color: #FFF;
      }
   }
}