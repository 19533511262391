@import '../../../scss/media';


.first_block {

   &_wrap {
      &_head {
         display: flex;
         flex-direction: column;
         align-items: center;
         row-gap: 30px;
         row-gap: clamp(1rem, -3.375rem + 4.375vw, 1.875rem);
         // 30px > 16px
         width: 100%;
         max-width: 1070px;
         max-width: clamp(60rem, 25.625rem + 34.375vw, 66.875rem);
         // 1070px > 960px
         margin-left: auto;
         margin-right: auto;

         @include desktop {
            row-gap: 16px;
            max-width: 970px;
         }

         @include tablet {
            max-width: 540px;
         }

         &-icon {
            display: flex;
            animation-name: rotateIcon;
            animation-duration: 3s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;

            width: 60px;
            width: clamp(3.125rem, 0rem + 3.125vw, 3.75rem);
            // 60px > 50px
            height: 60px;
            height: clamp(3.125rem, 0rem + 3.125vw, 3.75rem);
            // 60px > 50px

            @include desktop {
               width: 50px;
               height: 50px;
            }

            @include tablet {
               width: 40px;
               height: 40px;
            }

            svg {
               width: 100%;
               height: 100%;
            }
         }

         @keyframes rotateIcon {
            0% {
               transform: perspective(2000px) rotateY(0deg);
            }

            50% {
               transform: perspective(2000px) rotateY(180deg);
            }

            100% {
               transform: perspective(2000px) rotateY(0deg);
            }
         }

         &-title {
            display: block;

            color: #121212;
            text-align: center;
            font-size: 56px;
            font-size: clamp(3.125rem, 1.25rem + 1.875vw, 3.5rem);
            // 56px > 50px
            font-weight: 400;
            line-height: 64px;
            line-height: clamp(3.625rem, 1.75rem + 1.875vw, 4rem);
            // 64px > 58px
            letter-spacing: -1.32px;

            @include desktop {
               font-size: 50px;
               line-height: 58px;
               letter-spacing: -1px;
            }

            @include tablet {
               font-size: 28px;
               line-height: normal;
               letter-spacing: -0.56px;
            }
         }

         &-text {
            color: #121212;
            text-align: center;
            font-size: 26px;
            font-size: clamp(1.25rem, -0.625rem + 1.875vw, 1.625rem);
            // 26px > 20px
            font-weight: 400;
            line-height: 34px;
            line-height: clamp(1.75rem, -0.125rem + 1.875vw, 2.125rem);
            // 34px > 28px
            letter-spacing: 0.52px;

            @include desktop {
               font-size: 20px;
               line-height: 28px;
               letter-spacing: 0.4px;
            }

            @include tablet {
               font-size: 18px;
               line-height: normal;
               letter-spacing: 0.36px;
            }
         }

         &-btn {
            margin-top: 10px;

            @include desktop {
               margin-top: 14px;
            }

            @include tablet {
               margin-top: 0px;
            }
         }
      }

      &_bot {
         display: flex;
         flex-direction: column;
         align-items: center;
         margin-top: 58px;
         margin-top: clamp(3.125rem, 0.625rem + 2.5vw, 3.625rem);
         // 58px > 50px
         row-gap: 30px;
         row-gap: clamp(1.5rem, -0.375rem + 1.875vw, 1.875rem);
         // 30px > 24px
         width: 100%;
         max-width: 1165px;
         max-width: clamp(49.688rem, -65.938rem + 115.625vw, 72.813rem);
         // 1165px > 795px
         margin-left: auto;
         margin-right: auto;

         @include desktop {
            margin-top: 50px;
            row-gap: 24px;
            max-width: 795px;
         }

         @include tablet {
            margin-top: 60px;
            max-width: unset;
            width: calc(100% + 26px);
            transform: translateX(-13px);
         }

         @include mobile {
            margin-top: 80px;
         }

         &-title {
            color: #C9C9C9;
            text-align: center;
            font-family: "IBM Plex Mono";
            font-size: 20px;
            font-size: clamp(1.125rem, 0.5rem + 0.625vw, 1.25rem);
            // 20px > 18px
            font-weight: 400;
            line-height: 28px;
            line-height: clamp(1.625rem, 1rem + 0.625vw, 1.75rem);
            //  28px > 26px
            letter-spacing: 0.4px;

            @include desktop {
               font-size: 18px;
               line-height: normal;
               letter-spacing: 0.36px;
            }

            @include tablet {
               font-size: 16px;
               line-height: normal;
               letter-spacing: 0.32px;
            }
         }

         &-marquee {
            column-gap: 50px;
            column-gap: clamp(1.5rem, -6.625rem + 8.125vw, 3.125rem);
            // 50px > 24px

            @include desktop {
               column-gap: 24px;
            }

            .rfm-marquee {
               column-gap: 50px;
               column-gap: clamp(1.5rem, -6.625rem + 8.125vw, 3.125rem);
               // 50px > 24px

               @include desktop {
                  column-gap: 24px;
               }
            }
         }

         &_list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            row-gap: 10px;
            column-gap: 50px;
            column-gap: clamp(1.5rem, -6.625rem + 8.125vw, 3.125rem);
            // 50px > 24px

            @include desktop {
               column-gap: 24px;
            }

            &-item {
               display: flex;
               align-items: center;
               width: auto;
               height: 24px;
               height: clamp(1.375rem, 0.75rem + 0.625vw, 1.5rem);
               // 24px > 22px
               max-height: 24px;
               max-height: clamp(1.375rem, 0.75rem + 0.625vw, 1.5rem);
               // 24px > 22px
               overflow: hidden;

               @include desktop {
                  height: 22px;
                  max-height: 22px;
               }

               @include tablet {
                  height: 16px;
                  max-height: 16px;
               }

               svg,
               img {
                  width: auto;
                  max-height: 100%;
                  object-fit: contain;
               }
            }
         }
      }

      &_cards {
         margin-top: 120px;

         @include desktop {
            margin-top: 100px;
         }

         @include tablet {
            margin-top: 60px;
         }
      }
   }
}