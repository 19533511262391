@import '../../../../scss/media';

.source {
   &_list {
      row-gap: 20px;

      @include tablet {
         row-gap: 10px;
      }

      .col-md-6 {
         overflow: hidden;
      }

      &_item {
         position: relative;
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         padding: 50px 49px 20px 49px;
         padding-top: clamp(2.438rem, -1rem + 3.438vw, 3.125rem);
         padding-right: clamp(2.438rem, -0.688rem + 3.125vw, 3.063rem);
         padding-left: clamp(2.438rem, -0.688rem + 3.125vw, 3.063rem);
         border-radius: 10px;
         background: #252525;
         height: 100%;
         min-height: 540px;
         min-height: clamp(23.75rem, -26.25rem + 50vw, 33.75rem);
         // overflow: hidden;
         row-gap: 20px;

         $border: 1px;
         background-clip: padding-box;
         /* !importanté */
         border: solid $border transparent;
         /* !importanté */
         border-radius: 1em;


         &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -$border;
            /* !importanté */
            border-radius: inherit;
            /* !importanté */
            background: linear-gradient(to bottom, #5E5E5E 0%, #353535 100%);
         }

         @include desktop {
            padding: 39px 39px 20px 39px;
            min-height: 380px;
            row-gap: 40px;
         }

         @include tablet {
            row-gap: 20px;
            padding: 19px;
            min-height: 320px;
         }

         @include mobile {
            min-height: unset;
            height: auto;
         }

         &_head {
            padding: 0 50px;
            padding: 0 clamp(0rem, -15.625rem + 15.625vw, 3.125rem);
            // 50px > 0px

            @include desktop {
               padding: 0px;
            }

            &-title {
               color: #FFF;
               text-align: center;
               font-size: 26px;
               font-size: clamp(1.25rem, -0.625rem + 1.875vw, 1.625rem);
               // 26px > 20px
               font-weight: 400;
               line-height: 34px;
               line-height: clamp(1.75rem, -0.125rem + 1.875vw, 2.125rem);
               // 34px > 28px
               letter-spacing: 0.52px;

               @include desktop {
                  font-size: 20px;
                  line-height: 28px;
                  letter-spacing: 0.4px;
               }

               @include tablet {
                  font-size: 18px;
                  line-height: normal;
                  letter-spacing: 0.36px;
               }
            }

            &-text {
               margin-top: 20px;
               padding: 0 10px;

               @include tablet {
                  padding: 0px;
               }
            }


         }

         &.item_first {

            @include mobile {
               row-gap: 40px;
            }

            .icon {
               svg .svg-elem-1 {
                  stroke-dashoffset: 430.1910095214844px;
                  stroke-dasharray: 430.1910095214844px;
                  fill: transparent;
                  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
                  transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
               }

               svg.active .svg-elem-1 {
                  stroke-dashoffset: 860.3820190429688px;
                  fill: rgb(46, 46, 46);
               }

               svg .svg-elem-2 {
                  stroke-dashoffset: 430.1910095214844px;
                  stroke-dasharray: 430.1910095214844px;
                  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.12s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
                  transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.12s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
               }

               svg.active .svg-elem-2 {
                  stroke-dashoffset: 860.3820190429688px;
               }

               svg .svg-elem-3 {
                  stroke-dashoffset: 23.268726348876953px;
                  stroke-dasharray: 23.268726348876953px;
                  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.24s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
                  transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.24s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
               }

               svg.active .svg-elem-3 {
                  stroke-dashoffset: 46.537452697753906px;
               }

               svg .svg-elem-4 {
                  stroke-dashoffset: 23.268722534179688px;
                  stroke-dasharray: 23.268722534179688px;
                  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.36s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
                  transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.36s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
               }

               svg.active .svg-elem-4 {
                  stroke-dashoffset: 46.537445068359375px;
               }

               svg .svg-elem-5 {
                  stroke-dashoffset: 23.268722534179688px;
                  stroke-dasharray: 23.268722534179688px;
                  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.48s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s;
                  transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.48s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s;
               }

               svg.active .svg-elem-5 {
                  stroke-dashoffset: 46.537445068359375px;
               }

               svg .svg-elem-6 {
                  stroke-dashoffset: 23.268726348876953px;
                  stroke-dasharray: 23.268726348876953px;
                  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.6s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s;
                  transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.6s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s;
               }

               svg.active .svg-elem-6 {
                  stroke-dashoffset: 46.537452697753906px;
               }

               svg .svg-elem-7 {
                  stroke-dashoffset: 19.777999877929688px;
                  stroke-dasharray: 19.777999877929688px;
                  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.72s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s;
                  transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.72s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s;
               }

               svg.active .svg-elem-7 {
                  stroke-dashoffset: 39.555999755859375px;
               }

               svg .svg-elem-8 {
                  stroke-dashoffset: 19.777999877929688px;
                  stroke-dasharray: 19.777999877929688px;
                  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.84s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s;
                  transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.84s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s;
               }

               svg.active .svg-elem-8 {
                  stroke-dashoffset: 39.555999755859375px;
               }

               svg .svg-elem-9 {
                  stroke-dashoffset: 750.191162109375px;
                  stroke-dasharray: 750.191162109375px;
                  fill: transparent;
                  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.96s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s;
                  transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.96s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s;
               }

               svg.active .svg-elem-9 {
                  stroke-dashoffset: 1500.38232421875px;
                  fill: rgb(46, 46, 46);
               }

               svg .svg-elem-10 {
                  stroke-dashoffset: 750.191162109375px;
                  stroke-dasharray: 750.191162109375px;
                  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 1.08s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s;
                  transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 1.08s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s;
               }

               svg.active .svg-elem-10 {
                  stroke-dashoffset: 1500.38232421875px;
               }

               svg .svg-elem-11 {
                  stroke-dashoffset: 259.8072509765625px;
                  stroke-dasharray: 259.8072509765625px;
                  fill: transparent;
                  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 1.2s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s;
                  transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 1.2s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s;
               }

               svg.active .svg-elem-11 {
                  stroke-dashoffset: 519.614501953125px;
                  fill: rgb(228, 164, 164);
               }

               svg .svg-elem-12 {
                  stroke-dashoffset: 151.6161651611328px;
                  stroke-dasharray: 151.6161651611328px;
                  fill: transparent;
                  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 1.3199999999999998s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s;
                  transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 1.3199999999999998s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s;
               }

               svg.active .svg-elem-12 {
                  stroke-dashoffset: 303.2323303222656px;
                  fill: rgb(201, 201, 201);
               }

               svg .svg-elem-13 {
                  stroke-dashoffset: 27.481117248535156px;
                  stroke-dasharray: 27.481117248535156px;
                  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 1.44s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
                  transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 1.44s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
               }

               svg.active .svg-elem-13 {
                  stroke-dashoffset: 54.96223449707031px;
               }

               svg .svg-elem-14 {
                  stroke-dashoffset: 27.481117248535156px;
                  stroke-dasharray: 27.481117248535156px;
                  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 1.56s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s;
                  transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 1.56s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s;
               }

               svg.active .svg-elem-14 {
                  stroke-dashoffset: 54.96223449707031px;
               }

               svg .svg-elem-15 {
                  stroke-dashoffset: 268.96600341796875px;
                  stroke-dasharray: 268.96600341796875px;
                  fill: transparent;
                  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 1.68s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s;
                  transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 1.68s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s;
               }

               svg.active .svg-elem-15 {
                  stroke-dashoffset: 537.9320068359375px;
                  fill: rgb(185, 204, 236);
               }

               svg .svg-elem-16 {
                  stroke-dashoffset: 140.2730255126953px;
                  stroke-dasharray: 140.2730255126953px;
                  fill: transparent;
                  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 1.7999999999999998s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s;
                  transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 1.7999999999999998s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s;
               }

               svg.active .svg-elem-16 {
                  stroke-dashoffset: 280.5460510253906px;
                  fill: rgb(201, 201, 201);
               }

               svg .svg-elem-17 {
                  stroke-dashoffset: 122px;
                  stroke-dasharray: 122px;
                  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 1.92s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4000000000000004s;
                  transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 1.92s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4000000000000004s;
               }

               svg.active .svg-elem-17 {
                  stroke-dashoffset: 244px;
               }

               svg .svg-elem-18 {
                  stroke-dashoffset: 42px;
                  stroke-dasharray: 42px;
                  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 2.04s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s;
                  transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 2.04s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s;
               }

               svg.active .svg-elem-18 {
                  stroke-dashoffset: 84px;
               }

               svg .svg-elem-19 {
                  stroke-dashoffset: 268.8409423828125px;
                  stroke-dasharray: 268.8409423828125px;
                  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 2.16s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s;
                  transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 2.16s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s;
               }

               svg.active .svg-elem-19 {
                  stroke-dashoffset: 537.681884765625px;
               }

               svg .svg-elem-20 {
                  stroke-dashoffset: 759.865478515625px;
                  stroke-dasharray: 759.865478515625px;
                  fill: transparent;
                  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 2.28s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.7s;
                  transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 2.28s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.7s;
               }

               svg.active .svg-elem-20 {
                  stroke-dashoffset: 1519.73095703125px;
                  fill: rgb(59, 59, 59);
               }

               svg .svg-elem-21 {
                  stroke-dashoffset: 27.132741228718345px;
                  stroke-dasharray: 27.132741228718345px;
                  fill: transparent;
                  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 2.4s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.8s;
                  transition: stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 2.4s,
                     fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.8s;
               }

               svg.active .svg-elem-21 {
                  stroke-dashoffset: 54.26548245743669px;
                  fill: rgb(59, 59, 59);
               }

            }
         }

         &.item_second {

            @include desktop {
               padding-bottom: 0px;
            }

            .icon {
               padding-left: 25px;
               padding-right: 25px;
               transform: translateY(36px);

               @include desktop {
                  padding-right: 62px;
                  padding-right: clamp(1.813rem, -8.5rem + 13.75vw, 3.875rem);
                  // 62px > 29px
                  padding-left: 62px;
                  padding-left: clamp(1.813rem, -8.5rem + 13.75vw, 3.875rem);
                  // 62px > 29px
                  transform: translateY(0px);
               }

               @include tablet {
                  padding-right: 9px;
                  padding-left: 9px;
               }

               @include mobile {
                  padding-right: 0px;
                  padding-left: 0px;
               }

               svg {
                  .path1 {
                     animation-name: animka;
                     animation-duration: 2s;
                     animation-fill-mode: forwards;
                     animation-iteration-count: infinite;
                     animation-timing-function: linear;
                     opacity: 0;
                     transform: translateY(15px);
                  }

                  .path2 {
                     animation-name: animka;
                     animation-duration: 2s;
                     animation-fill-mode: forwards;
                     animation-iteration-count: infinite;
                     animation-timing-function: linear;
                     opacity: 0;
                     animation-delay: .2s;
                     transform: translateY(15px);
                  }

                  .path3 {
                     animation-name: animka;
                     animation-duration: 2s;
                     animation-fill-mode: forwards;
                     animation-iteration-count: infinite;
                     animation-timing-function: linear;
                     opacity: 0;
                     animation-delay: .4s;
                     transform: translateY(15px);
                  }

                  .path4 {
                     animation-name: animka;
                     animation-duration: 2s;
                     animation-fill-mode: forwards;
                     animation-iteration-count: infinite;
                     animation-timing-function: linear;
                     opacity: 0;
                     animation-delay: .6s;
                     transform: translateY(15px);
                  }

                  .path5 {
                     animation-name: animka;
                     animation-duration: 2s;
                     animation-fill-mode: forwards;
                     animation-iteration-count: infinite;
                     animation-timing-function: linear;
                     opacity: 0;
                     animation-delay: .8s;
                     transform: translateY(15px);
                  }

                  .path6 {
                     animation-name: animka;
                     animation-duration: 2s;
                     animation-fill-mode: forwards;
                     animation-iteration-count: infinite;
                     animation-timing-function: linear;
                     opacity: 0;
                     animation-delay: 1s;
                     transform: translateY(15px);
                  }
               }

               @keyframes animka {
                  0% {
                     opacity: 0;
                     transform: translateY(15px);
                     stroke-width: 1px;
                  }

                  50% {
                     opacity: 1;
                     transform: translateY(7px);
                     stroke-width: 2px;
                  }

                  100% {
                     opacity: 0;
                     transform: translateY(0px);
                     stroke-width: 3px;
                  }
               }
            }
         }

         .icon {
            display: flex;
            width: 100%;
            max-width: 100%;
            overflow: hidden;

            svg {
               width: 100%;
               max-width: 100%;
               height: auto;
               max-height: 100%;
            }
         }


      }
   }
}