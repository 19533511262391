@import '../../../scss/media';

.black_block {
   background: #212121;
   padding-top: 100px;
   margin-top: 120px;
   margin-top: clamp(6.25rem, 0rem + 6.25vw, 7.5rem);
   // 120px > 100px

   @media screen and (max-width: 1399px) {
      padding-bottom: 100px;
   }

   @include desktop {
      margin-top: 100px;
   }

   @include tablet {
      margin-top: 30px;
      padding-top: 30px;
      padding-bottom: 30px;
   }

   &_wrap {
      display: flex;
      flex-direction: column;
      row-gap: 120px;
      row-gap: clamp(6.25rem, 0rem + 6.25vw, 7.5rem);
      // 120px > 100px

      @include desktop {
         row-gap: 100px;
      }

      @include tablet {
         row-gap: 60px;
      }

      section {
         margin-top: 0px !important;
      }
   }
}